import React from 'react'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { Plus, Erase } from 'iconoir-react'
import { SharingOptionsFilters } from '../../../services/SharingOptions'
import { useRegulationPopUpQueries } from '../../../hooks/useRegulationPopUpQueries'
import SelectDropDownListV3 from '../../Commons/DropDownLists/SelectDropDownListV3'

const excludeStatuses = [
    { value: 'charged', label: 'Facturé' },
    { value: 'canceled', label: 'Annulé' },
    { value: 'charged and canceled', label: 'Facturé et annulé' },
]

const RegulationPopUpFilter = ({
    setShowPopUpFilter,
    patient,
    setPatient,
    partner,
    setPartner,
    setType,
    type,
    object,
    setObject,
    handleErase,
    setStatus,
    status,
    user,
    setUser,
    setShareFilter,
    shareFilter,
    excludeStatus,
    setExcludeStatus,
}) => {
    const {
        patientsQuery,
        partnersQuery,
        usersQuery,
        typesQuery,
        objectsQuery,
        statusesQuery,
        debouncedSearchRef,
    } = useRegulationPopUpQueries()

    const handleValidation = () => {
        setShowPopUpFilter(false)
    }

    return (
        <div className="invisible-scrollbar fixed bottom-0 left-0 z-50 flex h-full w-full overflow-y-scroll bg-gray-600/50 text-gray-800 shadow-md">
            <div className="mx-auto w-full max-w-md pt-20 lg:max-w-lg xl:max-w-3xl">
                <div className="min-h-filter flex flex-col rounded-xl bg-white p-4">
                    {/* Header */}
                    <div className="flex justify-between border-b py-2">
                        <div className="flex items-center">
                            <h1 className="text-2xl font-bold">Filtrer</h1>
                            <i className="fa fa-filter pl-2 text-base" />
                        </div>

                        <SecondaryButton
                            label="Réinitialiser"
                            icon={<Erase />}
                            action={handleErase}
                            className={
                                !patient &&
                                !partner &&
                                !object &&
                                !status &&
                                !user &&
                                'hidden'
                            }
                        />
                    </div>

                    {/* Filter Form */}
                    <div className="my-auto grid gap-2 pb-5 lg:grid-cols-2 lg:gap-5">
                        {/* Patient Select */}
                        <SelectDropDownListV3
                            icon="fa-user-injured"
                            label="Patient"
                            options={patientsQuery.data}
                            value={patient}
                            isSearchable={true}
                            isClearable={!!patient}
                            handleOptionChange={(e) => setPatient(e)}
                            handleSearchInputPatientChange={debouncedSearchRef}
                            isLoading={patientsQuery.isFetching}
                        />

                        {/* Partner Select */}
                        <SelectDropDownListV3
                            label="Partenaire"
                            options={partnersQuery.data || []}
                            value={partner}
                            isClearable={!!partner}
                            handleOptionChange={(e) => setPartner(e)}
                            icon="fa-hands-helping"
                            isLoading={partnersQuery.isLoading}
                        />

                        {/* Share Filter */}
                        <SelectDropDownListV3
                            label="Partage"
                            options={SharingOptionsFilters}
                            value={shareFilter}
                            isClearable={!!shareFilter}
                            handleOptionChange={(e) => setShareFilter(e)}
                            icon="fa-hand-holding"
                        />

                        {/* User Select */}
                        <SelectDropDownListV3
                            label="Utilisateur"
                            options={usersQuery.data || []}
                            value={user}
                            isClearable={!!user}
                            handleOptionChange={(e) => setUser(e)}
                            icon="fa-user"
                            isLoading={usersQuery.isLoading}
                        />

                        {/* Status Select */}
                        <SelectDropDownListV3
                            label="Statut"
                            options={statusesQuery.data || []}
                            value={status}
                            isClearable={!!status}
                            handleOptionChange={(e) => setStatus(e)}
                            icon="fa-info-circle"
                            isLoading={statusesQuery.isLoading}
                        />

                        {/* Type Select */}
                        <SelectDropDownListV3
                            label="Type"
                            options={typesQuery.data || []}
                            value={type}
                            isClearable={!!type}
                            handleOptionChange={(e) => setType(e)}
                            icon="fa-ambulance"
                            isLoading={typesQuery.isLoading}
                        />

                        {/* Object Select */}
                        <SelectDropDownListV3
                            label="Objet"
                            options={objectsQuery.data || []}
                            value={object}
                            isClearable={!!object}
                            handleOptionChange={(e) => setObject(e)}
                            icon="fa-bullseye"
                            isLoading={objectsQuery.isLoading}
                        />

                        {/* Exclude Status Select */}
                        <SelectDropDownListV3
                            label="Exclure statut"
                            options={excludeStatuses}
                            value={excludeStatus}
                            isClearable={!!excludeStatus}
                            handleOptionChange={(e) => setExcludeStatus(e)}
                            icon="fa-times"
                        />
                    </div>

                    {/* Footer Actions */}
                    <div className="mt-auto flex justify-between border-t pt-5">
                        <SecondaryButton
                            label="Annuler"
                            action={() => setShowPopUpFilter(false)}
                        />
                        <PrimaryButton
                            label="Valider"
                            action={handleValidation}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegulationPopUpFilter
