import React from 'react'
import { formatEuro } from '../Manage'
import StyledTextarea from '../../../Commons/StyledTextArea'
import { FREE_INVOICE_ACTIONS } from '../../../../services/Reducers/FreeInvoiceReducer'
import SelectDropDownListV3 from '../../../Commons/DropDownLists/SelectDropDownListV3'
import { FreeInvoicePaymentMethods } from '../../../../services/PaymentMethods'
import { getTotalHT, totalTax, getTotalTTC } from './FreeInvoiceWrapper'

// Utiliser les montants pré-calculés

export const FreeInvoiceTotal = ({ freeInvoiceForm, dispatch }) => {
    const {
        freeInvoiceLines,
        note: invoiceNote,
        paymentMethod,
        isInvoice,
    } = freeInvoiceForm

    return (
        <div className="mt-4 flex flex-col space-y-4">
            {isInvoice && (
                <div className={'w-full sm:w-1/2'}>
                    <SelectDropDownListV3
                        label={'Méthode de paiement'}
                        value={paymentMethod}
                        handleOptionChange={(e) =>
                            dispatch({
                                type: FREE_INVOICE_ACTIONS.SET_PAYMENT_METHOD,
                                payload: e,
                            })
                        }
                        options={FreeInvoicePaymentMethods}
                    />
                </div>
            )}
            <div className={'flex flex-col justify-between sm:flex-row'}>
                <div className={'w-full sm:w-1/2'}>
                    <StyledTextarea
                        label={'Note légale'}
                        value={invoiceNote}
                        onChange={(e) =>
                            dispatch({
                                type: FREE_INVOICE_ACTIONS.SET_NOTE,
                                payload: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="flex w-full  justify-end sm:w-1/2">
                    <div
                        className={
                            'flex-end flex w-64 flex-col justify-end space-y-2 text-lg'
                        }
                    >
                        <div className="flex justify-between">
                            <label>Total HT</label>
                            <div>
                                {formatEuro(getTotalHT(freeInvoiceLines))}
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <label>Total TVA</label>
                            <div>{formatEuro(totalTax(freeInvoiceLines))}</div>
                        </div>
                        <div className="flex justify-between text-xl font-bold">
                            <div className="flex justify-center">TOTAL TTC</div>
                            <div>
                                {formatEuro(getTotalTTC(freeInvoiceLines))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
