import React, { useEffect, useState } from 'react'
import SimpleSelectDropDownList from '../Commons/SimpleSelectDropDownList'
import API from '../../services/api'
import dayjs from 'dayjs'
import useConfiguratorStore from '../../stores/Configurator'
import NOTIFICATION from '../../services/notification'
import LoaderModal from '../Commons/Loaders/LoaderModal'
import ActionModal from './ActionModal'
import { toast } from 'react-toastify'
import SecondaryButton from '../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import { useQueryClient } from '@tanstack/react-query'
import { REGULATION_KEYS } from '../../hooks/useRunsList'

const AddToSeriesModal = ({ setAddToSeriesModalOpen, run }) => {
    const queryClient = useQueryClient()
    const [series, setSeries] = useState([])
    const [selectedSeries, setSelectedSeries] = useState(null)
    const [loading, setLoading] = useState(false)

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

    const handleSeriesChange = (event) => {
        setSelectedSeries(event)
    }

    const handleAddRunToSeries = (id, data, haveReturnOrLeadRun) => {
        API.Series.put(id, data).then((response) => {
            if (response.ok) {
                queryClient.invalidateQueries({
                    queryKey: REGULATION_KEYS.LIST,
                })
                toast.success(
                    `${
                        haveReturnOrLeadRun
                            ? 'Les transports ont bien étés ajoutés à la série.'
                            : 'Le transport a bien été ajouté à la série.'
                    }`
                )
                setAddToSeriesModalOpen(false)
            }
        })
    }
    const handleRemoveRunFromSeries = (id, data, haveReturnOrLeadRun) => {
        console.log('remove run from series', id)
        API.Series.put(id, data).then((response) => {
            if (response.ok) {
                toast.success(
                    `${
                        haveReturnOrLeadRun
                            ? 'Les transports ont bien étés retirés de la série.'
                            : 'Le transport a bien été retiré de la série.'
                    }`
                )
                setAddToSeriesModalOpen(false)
            }
        })
    }

    const handleAction = (confirmed) => {
        if (!selectedSeries && !run.series) {
            toast.error('Veuillez sélectionner une série.')
        } else {
            let data = {}
            if (run.series) {
                //remove run from series

                data = {
                    exitUniqueRun: true,
                    runToAddOrExit: run['@id'],
                }

                handleRemoveRunFromSeries(
                    run.series.id,
                    data,
                    !!(run.return || run.lead)
                )
            } else {
                //add run to series

                data = {
                    addUniqueRun: true,
                    runToAddOrExit: run['@id'],
                }

                if (
                    !confirmed &&
                    run.runObject.label !== selectedSeries.object
                ) {
                    //run object is different from series object
                    //ask confirmation
                    setOpenConfirmationModal(true)
                } else {
                    handleAddRunToSeries(
                        selectedSeries.id,
                        data,
                        !!(run.return || run.lead)
                    )
                }
            }
        }
    }

    const fetchSeries = () => {
        setLoading(true)
        API.Series.listByPatient(run.patient.id, true).then((response) => {
            response.json().then((data) => {
                const seriesList = data['hydra:member'].map((s) => {
                    const runs = s.runs.sort(function (a, b) {
                        return (
                            new Date(a.date) - new Date(b.date) ||
                            new Date(a.departureTime) -
                                new Date(b.departureTime)
                        )
                    })
                    console.log(runs)
                    return {
                        value: s['@id'],
                        id: s.id,
                        label: `${
                            s.label ? s.label : runs[0].runObject.label
                        }  - ${dayjs(s.firstRunDate).format(
                            'DD/MM/YY'
                        )} au ${dayjs(new Date(s.lastRunDate)).format(
                            'DD/MM/YY'
                        )}`,
                        object: runs[0].runObject.label,
                        serie: s,
                        newSeries: s.NewSeries,
                    }
                })
                setSelectedSeries(seriesList[0])
                setSeries(seriesList)
                setLoading(false)
            })
        })
    }

    useEffect(() => {
        if (!run.series) {
            fetchSeries()
        }
    }, [])

    return (
        <div className="fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50 pb-20 text-gray-700">
            <div
                className={`flex flex-col justify-between rounded-lg bg-white ${
                    run.series ? 'max-w-xl' : 'max-w-2xl '
                }  w-full space-y-4 p-2 px-4`}
            >
                <div className="flex flex-col space-y-12">
                    <div className="flex w-full border-b-2 pb-4 pt-2 lg:items-center">
                        <div className="flex w-full  items-center  justify-between font-bold">
                            <div className="flex items-center text-xl lg:text-xl xl:text-2xl ">
                                <p className="pl-2">
                                    {run.series
                                        ? 'Retirer le transport de sa série'
                                        : 'Ajouter le transport à une série'}
                                </p>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <LoaderModal customWidth={'w-8'} customHeight={'h-8'} />
                    ) : (
                        <div className="flex flex-col space-y-2">
                            {!run.series && (
                                <div className="flex w-full flex-col items-center px-5 pt-5 lg:flex-row">
                                    <div className="w-full pb-2 text-lg font-semibold lg:w-1/3 lg:p-0">
                                        Transport
                                    </div>
                                    <div className="flex w-full justify-between lg:w-2/3">
                                        <div className="w-1/2 truncate">
                                            <i className="fa fa-user pr-1" />
                                            {run.fullname}
                                        </div>
                                        <div className="w-1/2 truncate text-center">
                                            <i className="fa fa-clock pr-1" />
                                            {run.heure}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!run.series ? (
                                <div className="flex flex-col space-y-5 px-5 pt-5">
                                    <div className="flex w-full flex-col items-center lg:flex-row">
                                        <div className="w-full text-lg font-semibold lg:w-1/3">
                                            Série
                                        </div>
                                        <div className="flex w-full items-center lg:w-2/3">
                                            <SimpleSelectDropDownList
                                                options={series}
                                                value={selectedSeries}
                                                handleOptionChange={
                                                    handleSeriesChange
                                                }
                                                isDisabled={false}
                                                placeholder="Sélectionner une série"
                                            />
                                        </div>
                                    </div>
                                    {selectedSeries?.label && (
                                        <div className="flex w-full flex-col items-center lg:flex-row">
                                            <div className="w-full text-lg font-semibold lg:w-1/3">
                                                Détail
                                            </div>
                                            <div className="flex w-full items-center lg:w-2/3">
                                                {selectedSeries.serie.nbRuns}{' '}
                                                transports ->{' '}
                                                {selectedSeries.serie
                                                    .byIntervalDates
                                                    ? 'Interval de date'
                                                    : 'Bon de transport'}
                                            </div>
                                        </div>
                                    )}
                                    {selectedSeries?.serie && (
                                        <div className="flex w-full flex-col items-center lg:flex-row">
                                            <div className="w-full text-lg font-semibold lg:w-1/3">
                                                Objet
                                            </div>
                                            <div className="flex w-full items-center lg:w-2/3">
                                                {
                                                    selectedSeries.serie.runs[0]
                                                        .runObject.label
                                                }
                                            </div>
                                        </div>
                                    )}
                                    {selectedSeries?.serie && (
                                        <div className="flex w-full flex-col items-center lg:flex-row">
                                            <div className="w-full text-lg font-semibold lg:w-1/3">
                                                Date
                                            </div>
                                            <div className="flex w-full items-center lg:w-2/3">
                                                {dayjs(
                                                    selectedSeries.serie
                                                        .firstRunDate
                                                ).format('DD/MM/YYYY')}{' '}
                                                -{' '}
                                                {dayjs(
                                                    selectedSeries.serie
                                                        .lastRunDate
                                                ).format('DD/MM/YYYY')}
                                            </div>
                                        </div>
                                    )}
                                    {selectedSeries?.serie.description && (
                                        <div className="flex w-full flex-col items-center lg:flex-row">
                                            <div className="w-full text-lg font-semibold lg:w-1/3">
                                                Description
                                            </div>
                                            <div className="flex w-full items-center lg:w-2/3">
                                                {
                                                    selectedSeries.serie
                                                        .description
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="pl-2">
                                    {run.isRoundTrip
                                        ? 'Êtes-vous sur de vouloir retirer les transports aller-retour de leur série ?'
                                        : 'Êtes-vous sur de vouloir retirer ce transport de sa série ?'}
                                </div>
                            )}
                        </div>
                    )}
                    <div className="border-t-2">
                        <div className="flex justify-between pt-4">
                            <SecondaryButton
                                action={() => setAddToSeriesModalOpen(false)}
                                label="Annuler"
                                title="Annuler"
                                hiddenLabelOnMobile={false}
                            />
                            <PrimaryButton
                                action={() => {
                                    handleAction(false)
                                }}
                                label={run.series ? 'Retirer' : 'Ajouter'}
                                title={run.series ? 'Retirer' : 'Ajouter'}
                                hiddenLabelOnMobile={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {openConfirmationModal && (
                <ActionModal
                    setModalOpen={setOpenConfirmationModal}
                    label="L'objet du transport et celui de la série sont différents, voulez-vous continuer ?"
                    action={() => handleAction(true)}
                    labelAction="Confirmation"
                    actionButton="Continuer"
                />
            )}
        </div>
    )
}

export default AddToSeriesModal
