import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Route, Routes, useNavigate } from 'react-router-dom'
import * as dayjs from 'dayjs'
import { toast } from 'react-toastify'
import API from '../../../../services/api'
import Loader from '../../../Commons/Loaders/Loader'
import SubscriptionData from './SubscriptionData'
import SubscriptionPlans from './SubscriptionPlans'
import SubscriptionRecap from './SubscriptionRecap'
import SubscriptionCancel from './SubscriptionCancel'
import SubscriptionSuccess from './SubscriptionSuccess'

const SMS_OPTIONS = [
    { value: 50, label: '50 SMS - 25€', id: '50', price: 25 },
    { value: 100, label: '100 SMS - 45€', id: '100', price: 45 },
    { value: 200, label: '200 SMS - 80€', id: '200', price: 80 },
]

const SubscriptionContainer = () => {
    const navigate = useNavigate()
    const [plan, setPlan] = useState(null)
    const [acceptedUsedTerms, setAcceptedUsedTerms] = useState(false)
    const [nbSmsValue, setNbSmsValue] = useState(SMS_OPTIONS[0])

    // Récupération de l'ID société
    const societyId = localStorage.getItem('society')?.replace(/\D/g, '') ?? ''

    // Query société
    const { data: society, isLoading: isSocietyLoading } = useQuery({
        queryKey: ['society', societyId],
        queryFn: async () => {
            const response = await API.Society.get(societyId)
            return response.json()
        },
        enabled: Boolean(societyId),
    })

    // Query plans
    const { data: plansData, isLoading: isPlansLoading } = useQuery({
        queryKey: ['plans'],
        queryFn: async () => {
            const response = await API.Plans.get()
            const data = await response.json()
            return data['hydra:member']
        },
    })

    // Calcul jours d'essai restants
    const freeTryDaysLeft = React.useMemo(() => {
        if (society?.plan.id === 'free') {
            return 14 - dayjs().diff(dayjs(society.lastUpdatedPlanAt), 'days')
        }
        return 0
    }, [society])

    // Handlers
    const handlePlanSelection = (selectedPlan) => {
        setPlan(selectedPlan)
        navigate('/parametres/abonnement/plan/recapitulatif')
    }

    const handleSmsSelection = () => {
        const selectedPlan = plansData?.find((p) => p.id === nbSmsValue.id)
        if (selectedPlan) {
            setPlan(selectedPlan)
            navigate('/parametres/abonnement/credit/recapitulatif')
        }
    }

    const handleStripeCheckout = async (isSubscription) => {
        if (!acceptedUsedTerms) {
            toast.error(
                "Veuillez accepter nos conditions générales de vente et d'utilisation"
            )
            return
        }

        try {
            const response = await API.Stripe.CreateCheckout(
                {
                    priceId: plan?.stripePriceId,
                    userId: localStorage.getItem('id'),
                    societyId,
                    fromApp: true,
                    planIri: plan?.['@id'],
                },
                isSubscription
            )

            const { session } = await response.json()
            window.location.href = session.url
        } catch (err) {
            toast.error(
                'Une erreur est survenue lors de la redirection vers le paiement'
            )
        }
    }

    if (isSocietyLoading || isPlansLoading) {
        return <Loader />
    }

    return (
        <div className="max-w-4xl space-y-6 p-6">
            <Routes>
                <Route
                    path="/"
                    element={
                        <SubscriptionData
                            society={society}
                            freeTryDaysLeft={freeTryDaysLeft}
                            handleSmsPlan={handleSmsSelection}
                            smsOptions={SMS_OPTIONS}
                            selectedSmsOption={nbSmsValue}
                            onSmsOptionChange={setNbSmsValue}
                        />
                    }
                />
                <Route
                    path="/plans/*"
                    element={
                        <SubscriptionPlans
                            handlePlan={handlePlanSelection}
                            plans={plansData}
                        />
                    }
                />
                <Route
                    path="/plan/recapitulatif"
                    element={
                        <SubscriptionRecap
                            plan={plan}
                            acceptedUsedTerms={acceptedUsedTerms}
                            setAcceptedUsedTerms={setAcceptedUsedTerms}
                            goToStripe={() => handleStripeCheckout(true)}
                            isCreditSms={false}
                        />
                    }
                />
                <Route
                    path="/credit/recapitulatif"
                    element={
                        <SubscriptionRecap
                            plan={plan}
                            acceptedUsedTerms={acceptedUsedTerms}
                            setAcceptedUsedTerms={setAcceptedUsedTerms}
                            goToStripe={() => handleStripeCheckout(false)}
                            isCreditSms={true}
                        />
                    }
                />
                <Route
                    path="/paiement/cancel"
                    element={<SubscriptionCancel />}
                />
                <Route
                    path="/paiement/success"
                    element={<SubscriptionSuccess />}
                />
            </Routes>
        </div>
    )
}

export default SubscriptionContainer
