import React from 'react'
import { useNavigate } from 'react-router-dom'
import Success from '../../../Commons/Success'
import { useQueryClient } from '@tanstack/react-query'

const SubscriptionSuccess = () => {
    const queryClient = useQueryClient()
    let history = useNavigate()
    console.log('SubscriptionSuccess')

    const goTo = () => {
        queryClient.invalidateQueries({})
        // window.location.href = '/transport'
        history('/parametres/abonnement')
    }

    return <Success goTo={goTo} buttonLabel="Continuer" />
}

export default SubscriptionSuccess
