import React from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import SelectDropDownListV3 from '../../../Commons/DropDownLists/SelectDropDownListV3'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { toast } from 'react-toastify'
import { useSmsPurchase } from '../../../../hooks/useSmsPurchase'
import { formatEuro } from '../../Invoice/Manage'

const InfoBox = ({ children, className = '' }) => (
    <div
        className={`rounded-lg border-secondary-color bg-secondary-color/10 p-4  ${className}`}
    >
        {children}
    </div>
)

const SectionTitle = ({ children }) => (
    <h2 className="mb-4 text-xl font-bold text-gray-900">{children}</h2>
)

const BillingSection = () => (
    <div className=" ">
        <SectionTitle>Facture Heroad et informations de paiement</SectionTitle>
        <InfoBox>
            Utilisez votre email de facturation pour accéder à vos informations
            de paiement. Contactez le support si vous ne retrouvez pas votre
            adresse email.
        </InfoBox>
        <div className="mt-4">
            <PrimaryButton
                label="Gérer mon abonnement et factures"
                title="Accéder à l'espace de facturation"
                action={() =>
                    window.open(
                        'https://billing.stripe.com/p/login/aEU15Cfkx5l68VyaEE',
                        '_blank'
                    )
                }
                className="w-full sm:w-auto"
            />
        </div>
    </div>
)

const PriceRow = ({ label, amount, className = '' }) => (
    <div className={`flex justify-between py-2 ${className}`}>
        <span className="text-gray-700">{label}</span>
        <span
            className={`font-medium ${
                amount < 0 ? 'text-green-600' : 'text-gray-900'
            }`}
        >
            {amount.toFixed(2)} €
        </span>
    </div>
)

const SmsCreditsSection = ({
    totalSmsUsed,
    smsCreditsRemaining,
    nbSmsValue,
    nbSmsOptions,
    onSmsValueChange,
    onAddCredits,
    showSmsForm,
    setShowSmsForm,
}) => {
    const smsPurchaseMutation = useSmsPurchase()

    const handlePurchase = async () => {
        if (!nbSmsValue) {
            toast.error('Veuillez sélectionner une quantité de SMS')
            return
        }

        try {
            await smsPurchaseMutation.mutateAsync({
                quantity: nbSmsValue.value,
            })
        } catch (error) {
            // L'erreur est déjà gérée par la mutation
            console.error(error)
        }
    }
    const selectedPrice = nbSmsValue
        ? {
              ht: nbSmsValue.value * 0.1, // Prix unitaire HT
              tva: nbSmsValue.value * 0.1 * 0.2, // TVA 20%
          }
        : { ht: 0, tva: 0 }

    return (
        <div>
            <div className="mb-6">
                <h2 className="text-xl font-semibold text-gray-900">
                    Crédits SMS Rappel de RDV
                </h2>
                <div className="mt-4 space-y-4">
                    <div className="rounded-lg bg-secondary-color/10 p-4">
                        <div className="grid gap-2">
                            <div className="flex justify-between">
                                <span className="">Crédit restant</span>
                                <span className="font-semibold ">
                                    {smsCreditsRemaining} SMS
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span className="">SMS envoyés</span>
                                <span className="font-semibold ">
                                    {totalSmsUsed} SMS
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span className="">Coût du SMS</span>
                                <span className="font-semibold ">
                                    {formatEuro(0.1)}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6">
                        {!showSmsForm ? (
                            <PrimaryButton
                                label="Acheter des SMS"
                                title="Acheter des crédits SMS supplémentaires"
                                action={() => setShowSmsForm(true)}
                            />
                        ) : (
                            <div className="rounded-lg border border-gray-200 p-4">
                                <div className="space-y-4">
                                    <div>
                                        <label className="mb-2 block text-sm font-medium text-gray-700">
                                            Nombre de SMS
                                        </label>
                                        <SelectDropDownListV3
                                            label=""
                                            value={nbSmsValue}
                                            options={nbSmsOptions}
                                            handleOptionChange={
                                                onSmsValueChange
                                            }
                                            isSearchable={false}
                                            isClearable={false}
                                            placeholder="Sélectionnez le nombre de SMS"
                                        />
                                    </div>

                                    {nbSmsValue && (
                                        <div className="mt-4 rounded-lg bg-gray-50 p-4">
                                            <PriceRow
                                                label="Prix (HT)"
                                                amount={selectedPrice.ht}
                                            />
                                            <PriceRow
                                                label="TVA (20%)"
                                                amount={selectedPrice.tva}
                                            />
                                            <div className="my-2 border-t border-gray-200" />
                                            <PriceRow
                                                label="Total (TTC)"
                                                amount={
                                                    selectedPrice.ht +
                                                    selectedPrice.tva
                                                }
                                                className="font-medium"
                                            />
                                        </div>
                                    )}

                                    <div className="flex justify-between gap-3 pt-4">
                                        <SecondaryButton
                                            label="Annuler"
                                            action={() => setShowSmsForm(false)}
                                        />
                                        <PrimaryButton
                                            label="Valider l'achat"
                                            action={handlePurchase}
                                            disabled={
                                                !nbSmsValue ||
                                                smsPurchaseMutation.isPending
                                            }
                                            loading={
                                                smsPurchaseMutation.isPending
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const SubscriptionData = ({
    society,
    handleSmsPlan,
    nbSmsOptions,
    nbSmsValue,
    setNbSmsValue,
}) => {
    const navigate = useNavigate()
    const [showSmsForm, setShowSmsForm] = React.useState(false)

    if (!society) return null

    return (
        <div className="max-w-4xl space-y-6 ">
            <div className="flex items-center justify-between border-b border-gray-200 pb-6">
                <h1 className="text-2xl font-bold text-gray-900">
                    Gérer mon abonnement & SMS
                </h1>
            </div>
            <div className="space-y-6 rounded-lg border border-gray-100 bg-white p-6 shadow-sm">
                <BillingSection />
                <SmsCreditsSection
                    totalSmsUsed={society.totalSmsUsed}
                    smsCreditsRemaining={society.smsCreditsRemaining}
                    nbSmsValue={nbSmsValue}
                    nbSmsOptions={nbSmsOptions}
                    onSmsValueChange={setNbSmsValue}
                    onAddCredits={handleSmsPlan}
                    showSmsForm={showSmsForm}
                    setShowSmsForm={setShowSmsForm}
                />
            </div>
        </div>
    )
}

export default SubscriptionData
