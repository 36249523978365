import React from 'react'
import MobilePagination from '../../Commons/MobilePagination'
import MobileOption from '../../Commons/MobileOption'
import CardPaginationForcePaged from '../../Commons/Pagination/CardPaginationForcePaged'
import Loader from '../../Commons/Loaders/Loader'
import ShareModal from '../../Modal/ShareModal'
import ActionModal from '../../Modal/ActionModal'
import AddToSeriesModal from '../../Modal/AddToSeriesModal'
import { isRunAbleToBeTransferred } from '../../../services/fetchRunsInformations'
import { getBorderColor } from '../../../services/RunStatus'
import { LegendModal } from '../../Modal/LegendModal'
import { toast } from 'react-toastify'
import ComputerConfiguratorDisplayed from '../../Commons/ComputerConfiguratorDisplayed'
import { useLocation } from 'react-router-dom'
import { useRegulationList } from '../../../hooks/useRegulationList'
import MobileConfiguratorDisplayed from '../../Commons/MobileConfiguratorDisplayed'
import DefaultOptions from '../../Commons/DefaultOptions'

const RegulationListContainer = ({
    rows = [],
    setPage,
    totalItems = 0,
    loading,
    page,
    lastPage,
    isSocietyTaxi,
    handleDeleteElement,
    handleShareRun,
    handleRemoveShare,
    computerConfigurator,
    mobileConfigurator,
    transferGroup = [],
    updateTransferGroup,
    selectAll,
    setSelectAll,
    fetchRunsList,
}) => {
    const location = useLocation()
    const { screenWidth } = useWindowDimensions()
    const { states, setters, handlers, refs, bind } = useRegulationList()

    const {
        showMobileOptions,
        showOptions,
        showLegendModal,
        selectedRowId,
        selectedRow,
        styleId,
        modalStates,
        shareData,
        modalData,
    } = states

    const isTransferActive = location.search.includes('?statut=')

    const handleAddToTransferGroup = (run) => {
        updateTransferGroup((prev) => {
            if (prev.some((r) => r.id === run.id)) {
                return prev.filter((r) => r.id !== run.id)
            }
            return [...prev, run]
        })
    }

    const handleSelectAll = () => {
        const transferableRuns = rows.filter(isRunAbleToBeTransferred)
        if (!selectAll) {
            setSelectAll(true)
            updateTransferGroup(transferableRuns)
        } else {
            setSelectAll(false)
            updateTransferGroup([])
        }
    }

    const handleShareAction = () => {
        if (
            shareData.shareOption.value === 'mail' &&
            !shareData.partner?.email
        ) {
            toast.error("Le partenaire n'a pas d'adresse mail")
            return
        }

        const data = {
            partner: shareData.partner?.value || null,
            user: localStorage.getItem('email'),
            sharingOption: shareData.shareOption.value,
            note: shareData.note,
        }

        if (shareData.hasChanged && shareData.partner) {
            handleShareRun(selectedRowId, data)
        }
        setters.setModalStates((prev) => ({ ...prev, shareModal: false }))
    }

    return (
        <div className="px-2 xl:pt-5">
            {/* Header section */}
            <div className="relative flex flex-row items-center justify-between pb-2 text-xs lg:justify-start lg:text-base">
                <button
                    className="flex items-center bg-transparent text-xs font-bold hover:text-light-dark-green"
                    onClick={() => setters.setShowLegendModal(true)}
                >
                    <p className="pr-2 font-bold underline underline-offset-4">
                        Légende
                    </p>
                    <i className="far fa-question-circle fa-lg cursor-pointer pr-2" />
                </button>

                <div className="ml-auto text-xs font-bold">
                    {totalItems} Transports
                </div>

                {rows.some(isRunAbleToBeTransferred) && isTransferActive && (
                    <div className="absolute left-0 -ml-10 hidden h-10 w-10 cursor-pointer rounded pb-1 text-center xl:block">
                        <button
                            className={`form-checkbox h-3 w-3 cursor-pointer rounded-sm border-dark-green text-dark-green ring-2 ring-offset-2 ${
                                selectAll
                                    ? 'bg-green-500 ring-green-500'
                                    : 'bg-white ring-gray-500'
                            }`}
                            onClick={handleSelectAll}
                        />
                    </div>
                )}
            </div>

            {/* List section */}
            {loading ? (
                <Loader />
            ) : (
                <div className="min-height-70">
                    {rows.map((row) => (
                        <div
                            className="parents relative flex w-full pb-2"
                            key={row.id}
                        >
                            <div
                                className={`grid w-full cursor-pointer select-none rounded border-l-4 bg-white shadow-mobile lg:select-auto lg:border-l-8 ${
                                    row.id === styleId ? 'ring' : ''
                                } ${getBorderColor(row.status.id)} ${
                                    row.partner && row.isGiven ? 'stripeBg' : ''
                                } ${
                                    row.status.id === 'canceled'
                                        ? 'stripeBg_canceled'
                                        : ''
                                }`}
                                id={row.id}
                                {...bind}
                                onClick={() =>
                                    handlers.historyPushUpdate(row.id)
                                }
                            >
                                {screenWidth < 1024 ? (
                                    <MobileLayout
                                        row={row}
                                        mobileConfigurator={mobileConfigurator}
                                        isSocietyTaxi={isSocietyTaxi}
                                    />
                                ) : (
                                    <DesktopLayout
                                        row={row}
                                        computerConfigurator={
                                            computerConfigurator
                                        }
                                        isSocietyTaxi={isSocietyTaxi}
                                        showOptions={showOptions}
                                        selectedRowId={selectedRowId}
                                        handlers={handlers}
                                        setters={setters}
                                        refs={refs}
                                        isTransferActive={isTransferActive}
                                        transferGroup={transferGroup}
                                        handleAddToTransferGroup={
                                            handleAddToTransferGroup
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    ))}

                    {/* Pagination */}
                    {totalItems >= 100 && (
                        <>
                            <MobilePagination
                                title="Prises en charges"
                                setPage={setPage}
                                page={page}
                                totalItems={totalItems}
                                lastPage={lastPage}
                            />
                            <CardPaginationForcePaged
                                totalPages={Math.ceil(totalItems / 100)}
                                page={page}
                                handlePageClick={({ selected }) =>
                                    setPage(selected + 1)
                                }
                                totalItems={totalItems}
                                title="Prises en charges"
                            />
                        </>
                    )}
                </div>
            )}

            {/* Modals */}
            {showMobileOptions && (
                <MobileOption
                    setShowMobileOptions={setters.setShowMobileOptions}
                    rowId={selectedRowId}
                    historyPush={handlers.historyPush}
                    historyPushCopy={handlers.historyPushCopy}
                    setStyleId={setters.setStyleId}
                    handleModalShare={handlers.openShareModal}
                    handleModalDelete={handlers.openDeleteModal}
                    historyPushUpdate={handlers.historyPushUpdate}
                    isSeriesRun={
                        !!rows.find((r) => r.id === selectedRowId)?.series
                    }
                    handleModalAddToSeries={handlers.openAddToSeriesModal}
                    view={true}
                    isPartnerRun={!!selectedRow?.partner}
                />
            )}

            {showLegendModal && (
                <LegendModal setShowLegendModal={setters.setShowLegendModal} />
            )}

            {modalStates.actionModal && (
                <ActionModal
                    setModalOpen={(state) =>
                        setters.setModalStates((prev) => ({
                            ...prev,
                            actionModal: state,
                        }))
                    }
                    {...modalData}
                    action={() => {
                        setters.setModalStates((prev) => ({
                            ...prev,
                            actionModal: false,
                        }))
                        handleDeleteElement(selectedRowId)
                    }}
                />
            )}

            {modalStates.shareModal && (
                <ShareModal
                    setShareModalOpen={(state) =>
                        setters.setModalStates((prev) => ({
                            ...prev,
                            shareModal: state,
                        }))
                    }
                    handleActionShareRun={handleShareAction}
                    handleRemoveShare={handleRemoveShare}
                    {...modalData}
                    {...shareData}
                    setShareData={setters.setShareData}
                />
            )}

            {modalStates.addToSeriesModal && (
                <AddToSeriesModal
                    setAddToSeriesModalOpen={(state) =>
                        setters.setModalStates((prev) => ({
                            ...prev,
                            addToSeriesModal: state,
                        }))
                    }
                    run={modalData.addToSeriesRun}
                    setAddToSeriesRun={(run) =>
                        setters.setModalData((prev) => ({
                            ...prev,
                            addToSeriesRun: run,
                        }))
                    }
                    fetchRunsList={fetchRunsList}
                />
            )}
        </div>
    )
}
const MobileLayout = ({ row, mobileConfigurator, isSocietyTaxi }) => (
    <div className="h-42 parents row-span-1 flex grid-rows-1 flex-wrap items-center justify-between px-2 py-1 text-sm sm:text-base">
        {mobileConfigurator?.map((value, index) => (
            <MobileConfiguratorDisplayed
                key={`${value}-${index}`}
                componentType={value}
                row={row}
                isSocietyTaxi={isSocietyTaxi}
            />
        ))}
    </div>
)

const DesktopLayout = ({
    row,
    computerConfigurator,
    isSocietyTaxi,
    showOptions,
    selectedRowId,
    handlers,
    setters,
    refs,
    isTransferActive,
    handleAddToTransferGroup,
    transferGroup,
}) => {
    return (
        <div className="h-42 parents row-span-1 flex grid-rows-1 flex-wrap items-center justify-between px-2 py-1 text-base">
            {computerConfigurator?.map((value, index) => (
                <ComputerConfiguratorDisplayed
                    key={`${value}-${index}`}
                    componentType={value}
                    row={row}
                    isSocietyTaxi={isSocietyTaxi}
                />
            ))}

            {/* Options button */}
            <div
                className={`absolute right-0 hidden h-10 cursor-pointer rounded pt-2 xl:block ${
                    row.id === selectedRowId ? '' : 'options'
                }`}
                onClick={(e) => {
                    e.stopPropagation()
                    setters.setShowOptions(true)
                    setters.setSelectedRowId(row.id)
                    setters.setSelectedRow(row)
                    setters.setStyleId(row.id)
                }}
            >
                <i
                    className={`fas fa-ellipsis-h w-20 pr-4 pt-1 text-right ${
                        showOptions ? 'hidden' : ''
                    }`}
                />

                {row.id === selectedRowId && showOptions && (
                    <DefaultOptions
                        setShowMobileOptions={setters.setShowOptions}
                        rowId={selectedRowId}
                        row={row}
                        historyPush={handlers.historyPush}
                        historyPushCopy={handlers.historyPushCopy}
                        optionsRef={refs.optionsRef}
                        handleModalShare={handlers.openShareModal}
                        handleModalDelete={handlers.openDeleteModal}
                        historyPushUpdate={handlers.historyPushUpdate}
                        isSeriesRun={!!row.series}
                        handleModalAddToSeries={handlers.openAddToSeriesModal}
                        isPartnerRun={!!row.partner}
                    />
                )}
            </div>

            {/* Transfer checkbox */}
            {isRunAbleToBeTransferred(row) && isTransferActive && (
                <div
                    className="absolute left-0 -ml-10 hidden h-10 w-10 cursor-pointer rounded pt-1 text-center xl:block"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleAddToTransferGroup(row)
                    }}
                >
                    <button
                        className={`form-checkbox h-2 w-2 cursor-pointer rounded-sm border-dark-green text-dark-green ring-2 ring-offset-2 ${
                            transferGroup.some((r) => r.id === row.id)
                                ? 'bg-green-500 ring-green-500'
                                : 'bg-white ring-gray-500'
                        }`}
                    />
                </div>
            )}
        </div>
    )
}

// Hook pour la gestion des dimensions de la fenêtre
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = React.useState({
        screenWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
        screenHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
    })

    React.useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                screenWidth: window.innerWidth,
                screenHeight: window.innerHeight,
            })
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}

export default RegulationListContainer
