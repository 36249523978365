import { createContext, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Society } from '../services/API/Entities/society'

export const ParametersContext = createContext(null)

export const ParametersProvider = ({ children }) => {
    const {
        data: parameters,
        isLoading,
        error,
    } = useQuery({
        queryKey: ['parameters'],
        queryFn: Society.fetchParameters,
    })

    const value = {
        parameters,
        isLoading,
        error,
    }

    return (
        <ParametersContext.Provider value={value}>
            {children}
        </ParametersContext.Provider>
    )
}

export const useParameters = () => useContext(ParametersContext)
