import React, { useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { Route, Routes, useNavigate } from 'react-router-dom'
import * as dayjs from 'dayjs'
import { toast } from 'react-toastify'
import API from '../../../../services/api'
import Loader from '../../../Commons/Loaders/Loader'
import SubscriptionPlans from './SubscriptionPlans'
import SubscriptionData from './SubscriptionData'
import SubscriptionRecap from './SubscriptionRecap'
import SubscriptionCancel from './SubscriptionCancel'
import SubscriptionSuccess from './SubscriptionSuccess'

const SMS_OPTIONS = [
    { value: 100, label: '100 sms', id: '100' },
    { value: 500, label: '500 sms', id: '500' },
    { value: 1000, label: '1000 sms', id: '1000' },
    { value: 5000, label: '5000 sms', id: '5000' },
]

const SubscriptionContainer = () => {
    const navigate = useNavigate()

    const [plan, setPlan] = useState(null)
    const [acceptedUsedTerms, setAcceptedUsedTerms] = useState(false)
    const [nbSmsValue, setNbSmsValue] = useState(SMS_OPTIONS[0])

    // Récupération de l'ID société depuis le localStorage
    const societyId = localStorage.getItem('society')?.replace(/\D/g, '') ?? ''

    // Query pour récupérer les données de la société
    const { data: society, isLoading: isSocietyLoading } = useQuery({
        queryKey: ['society', societyId],
        queryFn: async () => {
            const response = await API.Society.get(societyId)
            return response.json()
        },
        enabled: Boolean(societyId),
    })

    // Query pour récupérer les plans
    const { data: plans, isLoading: isPlansLoading } = useQuery({
        queryKey: ['plans'],
        queryFn: async () => {
            const response = await API.Plans.get()
            const data = await response.json()
            return data['hydra:member']
        },
    })

    // Calcul des jours d'essai restants
    const freeTryDaysLeft = React.useMemo(() => {
        if (society?.plan.id === 'free') {
            const daysDiff = dayjs().diff(
                dayjs(society.lastUpdatedPlanAt),
                'days'
            )
            return 14 - daysDiff
        }
        return 0
    }, [society])

    // Mutation pour le checkout Stripe
    const stripeMutation = useMutation({
        mutationFn: async ({ data, subscription }) => {
            const response = await API.Stripe.CreateCheckout(data, subscription)
            const sessionData = await response.json()
            window.location.href = sessionData.session.url
        },
        onError: () => {
            toast.error(
                'Une erreur est survenue lors de la redirection vers le paiement'
            )
        },
    })

    // Gestion de la sélection du plan
    const handlePlan = (selectedPlan) => {
        setPlan(selectedPlan)
        navigate('/parametres/abonnement/plan/recapitulatif')
    }

    // Gestion de la sélection du plan SMS
    const handleSmsPlan = () => {
        const selectedPlan = plans?.find((p) => p.id === nbSmsValue.id)
        if (selectedPlan) {
            setPlan(selectedPlan)
            navigate('/parametres/abonnement/credit/recapitulatif')
        }
    }

    // Gestion du checkout Stripe
    const handleStripeCheckout = (subscription) => {
        if (!acceptedUsedTerms) {
            toast.error(
                "Veuillez accepter nos conditions générales de vente et d'utilisation"
            )
            return
        }

        const data = {
            priceId: plan?.stripePriceId,
            userId: localStorage.getItem('id'),
            societyId,
            fromApp: true,
            planIri: plan?.['@id'],
        }

        stripeMutation.mutate({ data, subscription })
    }

    // Affichage du loader pendant le chargement
    if (isSocietyLoading || isPlansLoading) {
        return <Loader />
    }

    return (
        <div className="max-w-7xl px-6 pt-6 text-lg lg:text-base">
            <Routes>
                <Route
                    path="/"
                    element={
                        <SubscriptionData
                            freeTryDaysLeft={freeTryDaysLeft}
                            society={society}
                            handleSmsPlan={handleSmsPlan}
                            nbSmsOptions={SMS_OPTIONS}
                            nbSmsValue={nbSmsValue}
                            setNbSmsValue={setNbSmsValue}
                        />
                    }
                />
                <Route
                    path="/plans/*"
                    element={
                        <SubscriptionPlans
                            handlePlan={handlePlan}
                            plans={plans}
                        />
                    }
                />
                <Route
                    path="/plan/recapitulatif"
                    element={
                        <SubscriptionRecap
                            plan={plan}
                            acceptedUsedTerms={acceptedUsedTerms}
                            setAcceptedUsedTerms={setAcceptedUsedTerms}
                            goToStripe={() => handleStripeCheckout(true)}
                            isCreditSms={false}
                            isLoading={stripeMutation.isPending}
                        />
                    }
                />
                <Route
                    path="/credit/recapitulatif"
                    element={
                        <SubscriptionRecap
                            plan={plan}
                            acceptedUsedTerms={acceptedUsedTerms}
                            setAcceptedUsedTerms={setAcceptedUsedTerms}
                            goToStripe={() => handleStripeCheckout(false)}
                            isCreditSms={true}
                            isLoading={stripeMutation.isPending}
                        />
                    }
                />
                <Route
                    path="/paiement/cancel"
                    element={<SubscriptionCancel />}
                />
                <Route
                    path="/paiement/success"
                    element={<SubscriptionSuccess />}
                />
            </Routes>
        </div>
    )
}

export default SubscriptionContainer
