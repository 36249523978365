import create from 'zustand'
import { useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

const useRegulationStoreBase = create((set) => ({
    // Search & Loading states
    search: '',
    refreshing: false,

    // Filters
    transferLabel: 'Statut',
    patient: null,
    partner: null,
    type: null,
    object: null,
    status: null,
    user: null,
    shareFilter: null,
    excludeStatus: null,

    // Pagination
    page: 1,

    // Selection states
    transferGroup: [],
    selectAll: null,

    // Actions
    setSearch: (search) => set({ search }),
    setRefreshing: (refreshing) => set({ refreshing }),
    setPage: (page) => set({ page }),
    setTransferLabel: (label) => set({ transferLabel: label }),
    setFilters: (filters) => set(filters),
    setPatient: (patient) => set({ patient }),
    setPartner: (partner) => set({ partner }),
    setType: (type) => set({ type }),
    setObject: (object) => set({ object }),
    setStatus: (status) => set({ status }),
    setUser: (user) => set({ user }),
    setShareFilter: (filter) => set({ shareFilter: filter }),
    setExcludeStatus: (status) => set({ excludeStatus: status }),
    updateTransferGroup: (updater) => {
        if (typeof updater === 'function') {
            set((state) => ({ transferGroup: updater(state.transferGroup) }))
        } else {
            set({ transferGroup: updater })
        }
    },
    setSelectAll: (value) => set({ selectAll: value }),

    resetFilters: () =>
        set({
            patient: null,
            partner: null,
            type: null,
            object: null,
            status: null,
            user: null,
            shareFilter: null,
            excludeStatus: null,
            transferGroup: [],
            selectAll: null,
            page: 1,
            search: '',
        }),
}))

export const useRegulationStore = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const store = useRegulationStoreBase()

    // Memoize complex actions
    const actions = useMemo(
        () => ({
            handleErase: () => {
                store.resetFilters()
                // set url to /transport
                navigate({ pathname: '/transport' })

                queryClient.invalidateQueries({ queryKey: ['runs'] })
            },

            handleTransferSelect: (run) => {
                const newGroup = store.transferGroup.some(
                    (item) => item.id === run.id
                )
                    ? store.transferGroup.filter((item) => item.id !== run.id)
                    : [...store.transferGroup, run]
                store.updateTransferGroup(newGroup)
            },

            handleSelectAll: (runs) => {
                if (!store.selectAll) {
                    store.setSelectAll(true)
                    store.updateTransferGroup(runs)
                } else {
                    store.setSelectAll(false)
                    store.updateTransferGroup([])
                }
            },
        }),
        [store.transferGroup, store.selectAll, queryClient]
    )

    return {
        ...store,
        ...actions,
    }
}
