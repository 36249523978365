import React, { useReducer, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../services/api'
import { toast } from 'react-toastify'
import Loader from '../../Commons/Loaders/Loader'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import { formatOptions } from '../../../tools/Utility'
import VehicleTypes from '../../../services/VehicleTypes'
import SelectDropDownListV3 from '../../Commons/DropDownLists/SelectDropDownListV3'
import { useNavigate } from 'react-router-dom'
import { Society } from '../../../services/API/Entities/society'

export const SettingsSection = ({ title, children }) => (
    <div className="rounded-lg border border-gray-100 bg-white p-6 shadow-sm">
        <h2 className="mb-6 text-lg font-semibold text-gray-900">{title}</h2>
        <div className="divide-y divide-gray-100">{children}</div>
    </div>
)

export const SettingRow = ({
    title,
    description = null,
    locked = false,
    children,
    className = '',
    icon = null,
}) => (
    <div className={`flex items-start justify-between  py-4 ${className}`}>
        <div className="flex-1">
            <div className="flex items-center gap-2">
                <span className="font-medium text-gray-900">{title}</span>
                {locked && (
                    <span
                        className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-100"
                        title="Paramètre verrouillé"
                    >
                        <span className="text-sm">🔒</span>
                    </span>
                )}
                {icon}
            </div>
            {description && (
                <p className="mt-1.5 text-sm leading-relaxed text-gray-500">
                    {description}
                </p>
            )}
        </div>
        <div className="flex-shrink-0 pt-1">{children}</div>
    </div>
)

const ParametersConfig = ({ parameters, smsCreditsRemaining }) => {
    const SmsReminder = [
        { value: 24, label: '24h avant' },
        { value: 48, label: '48h avant' },
    ]
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [formState, setFormState] = useState({
        usersShowAllRuns: parameters.usersShowAllRuns,
        usersShowUnassignedRuns: parameters.usersShowUnassignedRuns,
        hideFuturPlanningDaysForUsers: parameters.hideFuturPlanningDaysForUsers,
        showRunObjectColors: parameters.showRunObjectColors,
        allowedToChangePriceBasis: parameters.allowedToChangePriceBasis,
        taxi: parameters.taxi,
        smsReminderEnabled: parameters.smsReminderEnabled,
        smsReminderHoursBefore: SmsReminder.find(
            (item) => item.value === parameters.smsReminderHoursBefore
        ),
        allowedToInvoiceWithoutAMC: parameters.allowedToInvoiceWithoutAMC,
        defaultVehicleType: {
            label: parameters.defaultVehicleType.label,
            value: parameters.defaultVehicleType.label,
            ['@id']: parameters.defaultVehicleType['@id'],
        },
        defaultVehiclesTypes: parameters.defaultVehiclesTypes
            ? formatOptions(parameters.defaultVehiclesTypes)
            : null,
    })

    const mutation = useMutation({
        mutationFn: (data) => Society.putParameters(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['parameters'] })
            toast.success('Paramètres mis à jour avec succès')
        },
        onError: () => {
            toast.error('Une erreur est survenue')
        },
    })

    const handleChange = (key, value) => {
        setFormState((prev) => ({
            ...prev,
            [key]: value,
        }))
    }

    const handleSubmit = () => {
        if (!formState.defaultVehicleType || !formState.defaultVehiclesTypes) {
            toast.error('Veuillez configurer les types de véhicules')
            return
        }
        const dataToSend = {
            ...formState,
            defaultVehicleType: formState.defaultVehicleType['@id'],
            smsReminderHoursBefore: formState.smsReminderHoursBefore.value,
        }
        mutation.mutate(dataToSend)
    }

    const handleReset = () => {
        setFormState({
            usersShowAllRuns: parameters.usersShowAllRuns,
            usersShowUnassignedRuns: parameters.usersShowUnassignedRuns,
            hideFuturPlanningDaysForUsers:
                parameters.hideFuturPlanningDaysForUsers,
            showRunObjectColors: parameters.showRunObjectColors,
            allowedToChangePriceBasis: parameters.allowedToChangePriceBasis,
            allowedToInvoiceWithoutAMC: parameters.allowedToInvoiceWithoutAMC,
            taxi: parameters.taxi,
            smsReminderEnabled: parameters.smsReminderEnabled,
            smsReminderHoursBefore: SmsReminder.find(
                (item) => item.value === parameters.smsReminderHoursBefore
            ),
            defaultVehicleType: {
                label: parameters.defaultVehicleType.label,
                value: parameters.defaultVehicleType.label,
                ['@id']: parameters.defaultVehicleType['@id'],
            },
            defaultVehiclesTypes: parameters.defaultVehiclesTypes
                ? formatOptions(parameters.defaultVehiclesTypes)
                : null,
        })
    }

    const handleSelectVehicleTypes = (selectedOptions) => {
        if (!selectedOptions) {
            setFormState((prev) => ({
                ...prev,
                defaultVehiclesTypes: null,
                defaultVehicleType: null,
            }))
            return
        }

        setFormState((prev) => {
            const newState = {
                ...prev,
                defaultVehiclesTypes: selectedOptions,
            }

            if (
                !selectedOptions.some(
                    (type) => type['@id'] === prev.defaultVehicleType?.['@id']
                )
            ) {
                newState.defaultVehicleType = selectedOptions[0]
            }

            return newState
        })
    }

    if (mutation.isPending) return <Loader />

    return (
        <div className="max-w-4xl space-y-6 p-6">
            <div className="flex items-center justify-between border-b border-gray-200 pb-6">
                <h1 className="text-2xl font-bold text-gray-900">
                    Paramètres généraux
                </h1>
            </div>

            <div className="grid gap-6">
                <SettingsSection title="Accès Chauffeurs">
                    <SettingRow
                        title="Visualisation des courses collègues"
                        description="Permet aux chauffeurs de voir les courses assignées à leurs collègues"
                    >
                        <ClassicSwitch
                            value={formState.usersShowAllRuns}
                            setValue={() =>
                                handleChange(
                                    'usersShowAllRuns',
                                    !formState.usersShowAllRuns
                                )
                            }
                        />
                    </SettingRow>

                    <SettingRow
                        title="Visualisation des courses non assignées"
                        description="Permet aux chauffeurs de voir les courses sans chauffeur attribué"
                    >
                        <ClassicSwitch
                            value={formState.usersShowUnassignedRuns}
                            setValue={() =>
                                handleChange(
                                    'usersShowUnassignedRuns',
                                    !formState.usersShowUnassignedRuns
                                )
                            }
                        />
                    </SettingRow>

                    <SettingRow
                        title="Restriction planning futur"
                        description="Les chauffeurs ne peuvent voir le planning du lendemain qu'à partir de 18h"
                    >
                        <ClassicSwitch
                            value={formState.hideFuturPlanningDaysForUsers}
                            setValue={() =>
                                handleChange(
                                    'hideFuturPlanningDaysForUsers',
                                    !formState.hideFuturPlanningDaysForUsers
                                )
                            }
                        />
                    </SettingRow>
                </SettingsSection>

                <SettingsSection title="Facturation">
                    <SettingRow
                        title="Utilisation des heures effectives"
                        description="Utilise les heures réelles pour la télétransmission"
                        locked={true}
                    >
                        <ClassicSwitch
                            value={formState.transfertEffectiveHours}
                            disabled={true}
                        />
                    </SettingRow>

                    <SettingRow
                        title="Modification des tarifs 2023 / 2024"
                        description="Permet de changer les tarifs (si disponible) selon l'année définie "
                    >
                        <ClassicSwitch
                            value={formState.allowedToChangePriceBasis}
                            setValue={() =>
                                handleChange(
                                    'allowedToChangePriceBasis',
                                    !formState.allowedToChangePriceBasis
                                )
                            }
                        />
                    </SettingRow>

                    <SettingRow
                        title="Facturation directe au patient"
                        description="Permet la facturation au patient sans complémentaire"
                    >
                        <ClassicSwitch
                            value={formState.allowedToInvoiceWithoutAMC}
                            setValue={() =>
                                handleChange(
                                    'allowedToInvoiceWithoutAMC',
                                    !formState.allowedToInvoiceWithoutAMC
                                )
                            }
                        />
                    </SettingRow>
                </SettingsSection>
                <SettingsSection title="Régulation">
                    <SettingRow
                        title="Couleurs des objets de course"
                        description="Permet de faire une distinction entre les types de courses dans la partie régulation"
                    >
                        <ClassicSwitch
                            value={formState.showRunObjectColors}
                            setValue={() =>
                                handleChange(
                                    'showRunObjectColors',
                                    !formState.showRunObjectColors
                                )
                            }
                        />
                    </SettingRow>
                    <SettingRow
                        title={`Activer les rappels RDV SMS ${
                            smsCreditsRemaining > 0
                                ? `- Crédits disponible  ${smsCreditsRemaining}`
                                : '(Crédits insuffisants)'
                        }`}
                        description="Permet d'envoyer des rappels de RDV par SMS aux patients"
                    >
                        <ClassicSwitch
                            value={formState.smsReminderEnabled}
                            setValue={() =>
                                handleChange(
                                    'smsReminderEnabled',
                                    !formState.smsReminderEnabled
                                )
                            }
                        />
                    </SettingRow>

                    <SettingRow
                        title="Nombre d'heures avant le RDV"
                        description="Séléctionnez le nombre d'heures avant le RDV pour envoyer le rappel"
                        className="flex-col items-stretch gap-2"
                    >
                        <SelectDropDownListV3
                            label=""
                            options={SmsReminder}
                            value={
                                formState.smsReminderHoursBefore
                                    ? formState.smsReminderHoursBefore
                                    : SmsReminder[0]
                            }
                            handleOptionChange={(value) =>
                                handleChange('smsReminderHoursBefore', value)
                            }
                            className="w-full"
                        />

                        {formState.smsReminderEnabled &&
                        smsCreditsRemaining &&
                        smsCreditsRemaining <= 50 ? (
                            <div className={'pt-2'}>
                                <PrimaryButton
                                    label={'Ajouter des crédits SMS'}
                                    action={() =>
                                        navigate('/parametres/abonnement')
                                    }
                                />
                            </div>
                        ) : null}
                    </SettingRow>
                </SettingsSection>

                <SettingsSection title="Configuration des Véhicules">
                    <SettingRow
                        title="Types de véhicules disponibles"
                        description="Sélectionnez les types de véhicules utilisés par votre société"
                        className="flex-col items-stretch gap-2"
                    >
                        <SelectDropDownListV3
                            label=""
                            options={VehicleTypes}
                            value={formState.defaultVehiclesTypes}
                            handleOptionChange={(value) =>
                                handleSelectVehicleTypes(value)
                            }
                            isMulti={true}
                            className="w-full"
                        />
                    </SettingRow>
                    <SettingRow
                        title="Type de véhicule par défaut"
                        description="Ce type sera présélectionné lors de la création d'un véhicule"
                        className="flex-col items-stretch gap-2"
                    >
                        <SelectDropDownListV3
                            label=""
                            options={formState.defaultVehiclesTypes || []}
                            value={formState.defaultVehicleType}
                            handleOptionChange={(value) =>
                                handleChange('defaultVehicleType', value)
                            }
                            isDisabled={!formState.defaultVehiclesTypes}
                            className="w-full"
                        />
                    </SettingRow>
                </SettingsSection>
            </div>
            <div className="flex items-center justify-between gap-4 border-t   p-6">
                <SecondaryButton
                    label="Réinitialiser"
                    action={handleReset}
                    className="px-6 py-2"
                />
                <PrimaryButton
                    label="Enregistrer les modifications"
                    action={handleSubmit}
                    className="px-6 py-2"
                />
            </div>
        </div>
    )
}

export default ParametersConfig
