import StyledInput from '../../../Commons/StyledInput'
import StyledTextarea from '../../../Commons/StyledTextArea'
import DeleteButton from '../../../Commons/Buttons/DeleteButton'
import { Plus, Trash } from 'iconoir-react'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import { FREE_INVOICE_ACTIONS } from '../../../../services/Reducers/FreeInvoiceReducer'

export const FreeInvoiceLineItems = ({ freeInvoiceLines, dispatch }) => {
    const addItem = () => {
        dispatch({
            type: FREE_INVOICE_ACTIONS.SET_ITEMS,
            payload: [
                ...freeInvoiceLines,
                {
                    label: '',
                    unitPrice: '',
                    quantity: 1,
                    discount: 0,
                    vat: 10,
                    totalPrice: '',
                },
            ],
        })
    }

    const calculateTotalTTC = (unitPriceHT, quantity, vat, discount) => {
        const priceBeforeDiscount = unitPriceHT * quantity
        const priceAfterDiscount = priceBeforeDiscount * (1 - discount / 100)
        return priceAfterDiscount * (1 + vat / 100)
    }

    const calculateUnitPriceHT = (totalTTC, quantity, vat, discount) => {
        const priceBeforeVAT = totalTTC / (1 + vat / 100)
        const priceBeforeDiscount = priceBeforeVAT / (1 - discount / 100)
        return priceBeforeDiscount / quantity
    }

    const updateItem = (index, field, value) => {
        const newItems = freeInvoiceLines.map((item, i) => {
            if (i === index) {
                const updatedItem = { ...item, [field]: value }

                const quantity =
                    parseFloat(
                        String(updatedItem.quantity).replace(',', '.')
                    ) || 0
                const unitPrice =
                    parseFloat(
                        String(updatedItem.unitPrice).replace(',', '.')
                    ) || 0
                const vat =
                    parseFloat(String(updatedItem.vat).replace(',', '.')) || 0
                const discount =
                    parseFloat(
                        String(updatedItem.discount).replace(',', '.')
                    ) || 0
                const totalPrice =
                    parseFloat(
                        String(updatedItem.totalPrice).replace(',', '.')
                    ) || 0

                if (field === 'totalPrice') {
                    if (quantity && vat) {
                        const newUnitPriceHT = calculateUnitPriceHT(
                            totalPrice,
                            quantity,
                            vat,
                            discount
                        )
                        updatedItem.unitPrice = newUnitPriceHT.toFixed(3)
                    }
                } else if (
                    field === 'unitPrice' ||
                    field === 'quantity' ||
                    field === 'vat' ||
                    field === 'discount'
                ) {
                    if (quantity && unitPrice && vat) {
                        const newTotalPrice = calculateTotalTTC(
                            unitPrice,
                            quantity,
                            vat,
                            discount
                        )
                        updatedItem.totalPrice = newTotalPrice.toFixed(2)
                    }
                }

                return updatedItem
            }
            return item
        })

        dispatch({
            type: FREE_INVOICE_ACTIONS.SET_ITEMS,
            payload: newItems,
        })
    }

    const removeItem = (index) => {
        dispatch({
            type: FREE_INVOICE_ACTIONS.SET_ITEMS,
            payload: freeInvoiceLines.filter((_, i) => i !== index),
        })
    }

    return (
        <div className="space-y-4 py-4">
            {/* Version desktop */}
            <div className="hidden lg:block">
                {freeInvoiceLines.map((item, index) => (
                    <div key={index} className="mb-2 grid grid-cols-7 gap-4">
                        <div className="col-span-2">
                            <StyledTextarea
                                label="Description"
                                placeholder="Description"
                                value={item.label}
                                onChange={(e) =>
                                    updateItem(index, 'label', e.target.value)
                                }
                            />
                        </div>
                        <StyledInput
                            type="number"
                            label="Quantité"
                            placeholder="Quantité"
                            value={item.quantity}
                            onChange={(e) =>
                                updateItem(index, 'quantity', e.target.value)
                            }
                            onBlur={(e) => {
                                const formattedValue =
                                    parseFloat(
                                        String(e.target.value).replace(',', '.')
                                    ) || 0
                                updateItem(
                                    index,
                                    'quantity',
                                    formattedValue.toFixed(2)
                                )
                            }}
                        />
                        <StyledInput
                            label="Prix unitaire HT"
                            placeholder="Prix unitaire HT"
                            value={item.unitPrice}
                            onChange={(e) =>
                                updateItem(index, 'unitPrice', e.target.value)
                            }
                            onBlur={(e) => {
                                const formattedValue =
                                    parseFloat(
                                        String(e.target.value).replace(',', '.')
                                    ) || 0
                                updateItem(
                                    index,
                                    'unitPrice',
                                    formattedValue.toFixed(3)
                                )
                            }}
                        />
                        <StyledInput
                            label="Taxe (%)"
                            placeholder="Taxe (%)"
                            value={item.vat}
                            onChange={(e) =>
                                updateItem(index, 'vat', e.target.value)
                            }
                            onBlur={(e) => {
                                const formattedValue =
                                    parseFloat(
                                        String(e.target.value).replace(',', '.')
                                    ) || 0
                                updateItem(
                                    index,
                                    'vat',
                                    formattedValue.toFixed(2)
                                )
                            }}
                        />
                        <StyledInput
                            label="Remise (%)"
                            placeholder="Remise (%)"
                            value={item.discount}
                            onChange={(e) =>
                                updateItem(index, 'discount', e.target.value)
                            }
                            onBlur={(e) => {
                                const formattedValue =
                                    parseFloat(
                                        String(e.target.value).replace(',', '.')
                                    ) || 0
                                updateItem(
                                    index,
                                    'discount',
                                    formattedValue.toFixed(2)
                                )
                            }}
                        />
                        <div className="flex">
                            <StyledInput
                                label="Total TTC"
                                value={item.totalPrice}
                                onChange={(e) =>
                                    updateItem(
                                        index,
                                        'totalPrice',
                                        e.target.value
                                    )
                                }
                                onBlur={(e) => {
                                    const formattedValue =
                                        parseFloat(
                                            String(e.target.value).replace(
                                                ',',
                                                '.'
                                            )
                                        ) || 0
                                    updateItem(
                                        index,
                                        'totalPrice',
                                        formattedValue.toFixed(2)
                                    )
                                }}
                            />
                            <div className="ml-2 pt-6">
                                <DeleteButton
                                    icon={<Trash />}
                                    action={() => removeItem(index)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Version mobile */}
            <div className="lg:hidden">
                {freeInvoiceLines.map((item, index) => (
                    <div key={index} className="mb-6 rounded-lg border p-4">
                        <div className="mb-4 flex justify-end">
                            <DeleteButton
                                icon={<Trash />}
                                action={() => removeItem(index)}
                            />
                        </div>
                        <div className="space-y-4">
                            <StyledTextarea
                                label="Description"
                                placeholder="Description"
                                value={item.label}
                                onChange={(e) =>
                                    updateItem(index, 'label', e.target.value)
                                }
                            />
                            <div className="grid grid-cols-2 gap-4">
                                <StyledInput
                                    type="number"
                                    label="Quantité"
                                    placeholder="Quantité"
                                    value={item.quantity}
                                    onChange={(e) =>
                                        updateItem(
                                            index,
                                            'quantity',
                                            e.target.value
                                        )
                                    }
                                    onBlur={(e) => {
                                        const formattedValue =
                                            parseFloat(
                                                String(e.target.value).replace(
                                                    ',',
                                                    '.'
                                                )
                                            ) || 0
                                        updateItem(
                                            index,
                                            'quantity',
                                            formattedValue.toFixed(2)
                                        )
                                    }}
                                />
                                <StyledInput
                                    label="Prix unitaire HT"
                                    placeholder="Prix unitaire HT"
                                    value={item.unitPrice}
                                    onChange={(e) =>
                                        updateItem(
                                            index,
                                            'unitPrice',
                                            e.target.value
                                        )
                                    }
                                    onBlur={(e) => {
                                        const formattedValue =
                                            parseFloat(
                                                String(e.target.value).replace(
                                                    ',',
                                                    '.'
                                                )
                                            ) || 0
                                        updateItem(
                                            index,
                                            'unitPrice',
                                            formattedValue.toFixed(3)
                                        )
                                    }}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <StyledInput
                                    label="Taxe (%)"
                                    placeholder="Taxe (%)"
                                    value={item.vat}
                                    onChange={(e) =>
                                        updateItem(index, 'vat', e.target.value)
                                    }
                                    onBlur={(e) => {
                                        const formattedValue =
                                            parseFloat(
                                                String(e.target.value).replace(
                                                    ',',
                                                    '.'
                                                )
                                            ) || 0
                                        updateItem(
                                            index,
                                            'vat',
                                            formattedValue.toFixed(2)
                                        )
                                    }}
                                />
                                <StyledInput
                                    label="Remise (%)"
                                    placeholder="Remise (%)"
                                    value={item.discount}
                                    onChange={(e) =>
                                        updateItem(
                                            index,
                                            'discount',
                                            e.target.value
                                        )
                                    }
                                    onBlur={(e) => {
                                        const formattedValue =
                                            parseFloat(
                                                String(e.target.value).replace(
                                                    ',',
                                                    '.'
                                                )
                                            ) || 0
                                        updateItem(
                                            index,
                                            'discount',
                                            formattedValue.toFixed(2)
                                        )
                                    }}
                                />
                            </div>
                            <StyledInput
                                label="Total TTC"
                                value={item.totalPrice}
                                onChange={(e) =>
                                    updateItem(
                                        index,
                                        'totalPrice',
                                        e.target.value
                                    )
                                }
                                onBlur={(e) => {
                                    const formattedValue =
                                        parseFloat(
                                            String(e.target.value).replace(
                                                ',',
                                                '.'
                                            )
                                        ) || 0
                                    updateItem(
                                        index,
                                        'totalPrice',
                                        formattedValue.toFixed(2)
                                    )
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <PrimaryButton
                icon={<Plus />}
                label="Ajouter une ligne"
                action={addItem}
            />
        </div>
    )
}

export default FreeInvoiceLineItems
