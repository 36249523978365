import { useParams, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'
import NoemieStats from './NoemieStats'
import NoemieHeader from './NoemieHeader'
import NoemieLot from './NoemieLot'
import NoemieRejectionHistory from './NoemieRejectionHistory'
import SelectDateModalForm from './SelectDateModalForm'
import ActionModal from '../../../Modal/ActionModal'
import ManualNoemieDeclaration from './ManualNoemieDeclaration'
import { formatAndSortInvoices } from '../../../../services/InvoiceUtils'
import { Noemie } from '../../../../services/API/Entities/noemie'
import Loader from '../../../Commons/Loaders/Loader'
import dayjs from 'dayjs'
import ModalSkeleton from '../../../Modal/ModalSkeleton'
import {
    formatDateFromApiToTimeObject,
    formatDateToApi,
} from '../../../../services/dateUtils'

const NoemieForm = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    // États locaux
    const [openedCardId, setOpenedCardId] = useState(null)
    const [showSelectDateModal, setShowSelectDateModal] = useState(false)
    const [showManualDeclaration, setShowManualDeclaration] = useState(false)
    const [showResolvingModal, setShowResolvingModal] = useState(false)
    const [showResolvingUniqueModal, setShowResolvingUniqueModal] =
        useState(false)
    const [selectedLot, setSelectedLot] = useState(null)
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [actionInitiator, setActionInitiator] = useState(null)
    const [status, setStatus] = useState(null)
    const [date, setDate] = useState(new Date())
    const [rejectionReasons, setRejectionReasons] = React.useState({})
    const [selectedDate, setSelectedDate] = React.useState(new Date())
    const [rejectedInvoices, setRejectedInvoices] = React.useState([])

    // Requête principale pour récupérer les données NOEMIE
    const {
        data: noemie,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['noemie', id],
        queryFn: async () => {
            const response = await Noemie.get(id)
            console.log(response)
            if (response.cpamInvoiceGroups) {
                setDate(
                    formatDateFromApiToTimeObject(response.noemieReceivedAt)
                )
                response.cpamInvoiceGroups.forEach((group) => {
                    const formattedInvoices = formatAndSortInvoices(
                        group.cpamInvoices
                    )
                    group.rejectedInvoices = formattedInvoices.filter(
                        (invoice) => invoice.status === 'Rejetée'
                    )
                    setRejectedInvoices(group.rejectedInvoices)
                    group.validatedInvoices = formattedInvoices.filter(
                        (invoice) => invoice.status !== 'Rejetée'
                    )
                })
            }
            return response
        },
        enabled: !!id,
    })

    // Mutation pour l'impression
    const printMutation = useMutation({
        mutationFn: async () => {
            const blob = await Noemie.print(id)
            if (noemie?.cpamInvoiceGroups?.length > 0) {
                const groupIds = noemie.cpamInvoiceGroups.map(
                    (group) => group.groupId
                )
                const groupIdsString = groupIds.join('_')
                const pdfName = `${noemie.cpamInvoiceGroups[0].payingCenter.label}_${groupIdsString}.pdf`
                saveAs(blob, pdfName)
            }
        },
        onSuccess: () => {
            toast.success('PDF téléchargé avec succès')
        },
        onError: (error) => {
            toast.error('Erreur lors du téléchargement du PDF')
        },
    })

    // Mutation pour le paiement reçu
    const receivedPaymentMutation = useMutation({
        mutationFn: async () => {
            const data = {
                date: dayjs(date).format('YYYY-MM-DD'),
                organization: actionInitiator,
                b2FileIri: '/b2_files/' + id,
                status: status,
            }
            return await Noemie.receivedPayment(data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['noemie'] })
            toast.success('Status modifié avec succès')
            setShowSelectDateModal(false)
        },
    })

    // Mutation pour la résolution des factures rejetées
    const resolveRejectedMutation = useMutation({
        mutationFn: async () => {
            return await Noemie.resolveRejectedInvoices(selectedLot)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['noemie'] })
            toast.success('Factures résolues avec succès')
            setShowResolvingModal(false)
        },
    })

    const resolveUniqueRejectedMutation = useMutation({
        mutationFn: async () => {
            return await Noemie.resolveUniqueRejectedInvoices(selectedInvoice)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['noemie'] })
            toast.success('Facture résolue avec succès')
            setShowResolvingUniqueModal(false)
        },
    })

    const resolveManualDeclaration = useMutation({
        mutationFn: Noemie.resolveManualDeclaration,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['noemie'] })
            toast.success('Factures résolues avec succès')
            setShowManualDeclaration(false)
        },
    })

    const closeMutation = useMutation({
        mutationFn: async () => {
            return await Noemie.close(id)
        },
        onSuccess: () => {
            toast.success('Lot cloturé avec succès')
            navigate(-1)
        },
    })

    // Handlers
    const handleReceivedPaymentAMO = (status) => {
        setActionInitiator('AMO')
        setStatus(status)
        setShowSelectDateModal(true)
    }

    const handleReceivedPaymentAMC = (status) => {
        setActionInitiator('AMC')
        setStatus(status)
        setShowSelectDateModal(true)
    }

    const handleClosedNoemie = async () => {
        if (noemie?.cpamInvoiceGroups?.[0]?.rejectedInvoices?.length > 0) {
            toast.error(
                'Vous ne pouvez pas clôturer le retour car il y a des factures rejetées'
            )
            return
        }
        console.log('closeMutation.mutate()')
        closeMutation.mutate()
    }

    const handleDateChange = (date) => {
        if (date > new Date()) {
            toast.error('La date ne peut pas être dans le futur')
        } else {
            setDate(date)
        }
    }

    const handleShowLotResolvingModal = (lot) => {
        if (lot.id) {
            setShowResolvingUniqueModal(true)
            setSelectedInvoice(lot.id)
        } else {
            setShowResolvingModal(true)
            setSelectedLot(lot)
        }
    }

    if (isLoading) {
        return <Loader />
    }

    if (isError) {
        return <div>Erreur lors de la récupération des données</div>
    }

    const handleManualDeclaration = async () => {
        const data = {
            noemieId: id,
            date: formatDateToApi(selectedDate),
            rejectedInvoices: rejectedInvoices.map((invoice) => ({
                id: invoice.id,
                rejectionReason: invoice.rejectionReason,
            })),
            validatedInvoices: noemie.cpamInvoiceGroups
                .flatMap((group) => group.cpamInvoices)
                .filter(
                    (invoice) =>
                        !rejectedInvoices.find((i) => i.id === invoice.id)
                ),
        }
        resolveManualDeclaration.mutate(data)
    }

    return (
        <div className="px-2 xl:pt-5">
            <div className="flex w-full flex-col space-y-2 lg:space-y-0">
                <NoemieHeader
                    isReceived={!!noemie?.noemieReceivedAt}
                    noemieReceivedAt={noemie?.noemieReceivedAt}
                    handlePrintAction={() => printMutation.mutate()}
                    setShowManualDeclaration={setShowManualDeclaration}
                />
                <div className="py-5">
                    <NoemieStats
                        noemie={noemie}
                        handleReceivedPaymentAMC={handleReceivedPaymentAMC}
                        setActionInitiator={setActionInitiator}
                        handleReceivedPaymentAMO={handleReceivedPaymentAMO}
                        handleClosedNoemie={
                            noemie?.status === 'Réglé AMO'
                                ? handleClosedNoemie
                                : null
                        }
                    />
                </div>

                {noemie?.cpamInvoiceGroups?.map((lot) => (
                    <NoemieLot
                        key={lot.id}
                        lot={lot}
                        openedCardId={openedCardId}
                        setOpenedCardId={setOpenedCardId}
                        isReceived={!!noemie?.noemieReceivedAt}
                        handleShowLotResolvingModal={
                            handleShowLotResolvingModal
                        }
                    />
                ))}

                {noemie?.cpamInvoiceRejectionDetails?.length > 0 && (
                    <NoemieRejectionHistory noemie={noemie} />
                )}
            </div>

            {showSelectDateModal && (
                <SelectDateModalForm
                    date={date}
                    handleCloseModal={() => setShowSelectDateModal(false)}
                    handleDateChange={handleDateChange}
                    handleForm={() => receivedPaymentMutation.mutate()}
                />
            )}

            {showResolvingModal && (
                <ActionModal
                    action={() => resolveRejectedMutation.mutate()}
                    label="Les factures rejetées seront déduites du total du lot, mises en statut suspendu et placées dans l'historique des refus."
                    actionButton="Résoudre les factures rejetées"
                    setModalOpen={() => setShowResolvingModal(false)}
                    actionLabel="Résoudre"
                    labelAction="Résoudre les factures rejetées"
                />
            )}
            {showResolvingUniqueModal && (
                <ActionModal
                    action={() => resolveUniqueRejectedMutation.mutate()}
                    label="La facture rejetée sera déduite du total du lot, mis en statut suspendu et sera placée dans l'historique des refus."
                    actionButton="Résoudre la facture"
                    setModalOpen={() => setShowResolvingUniqueModal(false)}
                    actionLabel="Résoudre"
                    labelAction="Résoudre une facture"
                />
            )}

            {showManualDeclaration && (
                <ModalSkeleton
                    title="Déclarer un retour NOEMIE manuellement"
                    label={'Déclarer un retour NOEMIE'}
                    buttonLabel={'Confirmer le retour'}
                    handleCloseModal={() => setShowManualDeclaration(false)}
                    handleForm={handleManualDeclaration}
                    Component={ManualNoemieDeclaration}
                    componentProps={{
                        noemie,
                        rejectedInvoices,
                        setRejectedInvoices,
                        selectedDate,
                        setSelectedDate,
                        rejectionReasons,
                        setRejectionReasons,
                    }}
                />
            )}
        </div>
    )
}

export default NoemieForm
