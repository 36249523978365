import API from './api'
import { toast } from 'react-toastify'
import { formatDateYYYYMMDD, toLocalTimestamp } from './dateUtils'

const SERIES = {
    PostOrPut: async (
        global,
        pickUpAddress,
        depositAddress,
        history,
        fromModal,
        reRender,
        setOpenSeriesManageModal,
        setOpenNewSeriesManageModal,
        invalidSeriesList
    ) => {
        const { series, prescriptionForm } = global
        let prescription = prescriptionForm['@id']
            ? prescriptionForm['@id']
            : prescriptionForm.practitioner?.id
            ? prescriptionForm
            : null

        // prevent to change global address
        delete pickUpAddress.id
        delete pickUpAddress['@id']
        delete depositAddress.id
        delete depositAddress['@id']
        const sortedRunsToInsert = series.runsToInsert.sort((a, b) => {
            return a.date - b.date
        })
        let data = {
            patient: series.patient['@id'],
            prsDate: prescriptionForm?.date,
            prsNumber: series.prsNumber ? series.prsNumber : null,
            kilometer: series.kilometer ? parseInt(series.kilometer) : null,
            runObject: series.runObject['@id'],
            globalRunInformations: {
                type: series.globalRunInformations.type['@id'],
                depositLocation: depositAddress,
                pickUpLocation: pickUpAddress,
                isGiven: null,
                amount: series.globalRunInformations.amount
                    ? parseInt(series.globalRunInformations.amount)
                    : null,
            },
            description: series.comments,
            firstRunDate: formatDateYYYYMMDD(sortedRunsToInsert[0].date),
            lastRunDate: formatDateYYYYMMDD(
                sortedRunsToInsert[sortedRunsToInsert.length - 1].date
            ),
            byIntervalDates: series.byIntervalDates,
            label: series.label,
            nbRuns: series.byIntervalDates ? null : parseInt(series.nbRuns),
            frequency: series.frequency.value,
            days: series.days.map((day) => {
                // prevent to change global address
                delete day.pickUpLocation.id
                delete day.pickUpLocation['@id']
                delete day.depositLocation.id
                delete day.depositLocation['@id']

                return {
                    day: day.day,
                    depositLocation: day.depositLocation,
                    pickUpLocation: day.pickUpLocation,
                    differentReturnDeposit: day.differentReturnDeposit,
                    returnDepositLocation: day.differentReturnDeposit
                        ? day.returnDepositLocation
                        : null,
                    departureTime: day.departureTime,
                    arrivingTime: day.arrivingTime,
                    returnTime: day.returnTime,
                    comments: day.comments ? day.comments : null,
                    isReturnPath: day.isReturnPath,
                    waitingTime: day.waitingTime,
                    isRoundTrip: day.isRoundTrip,
                    isDirectReturn: day.isDirectReturn,
                    runType: day.runType.value,
                }
            }),
            runsToInsert: sortedRunsToInsert.map((run) => {
                return {
                    key: run.key,
                    date: toLocalTimestamp(run.date),
                    dateString: run.dateString,
                    departureTime: toLocalTimestamp(run.departureTime),
                    arrivingTime: toLocalTimestamp(run.arrivingTime),
                    returnTime: toLocalTimestamp(run.returnTime),
                    isReturnPath: run.isReturnPath,
                    isRoundTrip: run.isRoundTrip || false,
                    haveIndirectReturn:
                        (run.isRoundTrip && run.haveIndirectReturn) || false,
                    haveDirectReturn: run.haveDirectReturn || false,
                    waitingTime: run.waitingTime || null,
                    defaultMasterRunId: run.defaultMasterRunId || null,
                    masterRunId: run.masterRunId || null,
                }
            }),
        }
        if (prescription) {
            prescription = {
                ...prescriptionForm,
                id: prescriptionForm.id,
                rate: prescriptionForm.rate?.value,
                exoTicketModerateur:
                    prescriptionForm.exoTicketModerateur?.value,
                patient: series.patient['@id'],
                natureAssurance: prescriptionForm.natureAssurance?.value,
                payingCenter: prescriptionForm.payingCenter
                    ? prescriptionForm.payingCenter['@id']
                    : null,
                practitioner: prescriptionForm.practitioner
                    ? prescriptionForm.practitioner['@id']
                    : null,
            }
        }

        data = {
            ...data,
            prescription: prescription,
        }
        invalidSeriesList()

        if (!series.id) {
            await API.Series.post(data).then((response) => {
                if (response.ok) {
                    toast.success('La série a été créée.')
                    if (fromModal) {
                        setOpenSeriesManageModal(false)
                        setOpenNewSeriesManageModal(false)
                        reRender()
                    } else {
                        invalidSeriesList()
                        history('/series')
                    }
                }
            })
        } else {
            await API.Series.put(series.id, data).then((response) => {
                if (response.ok) {
                    toast.success('La série a été modifiée.')
                    if (fromModal) {
                        setOpenSeriesManageModal(false)
                        setOpenNewSeriesManageModal(false)
                        reRender()
                    } else {
                        // fetchRunsSeriesList()
                        // history('/series')
                    }
                    return response
                }
            })
        }
    },
}

export default SERIES
