import { apiClient, withErrorHandling } from './apiClient'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

const SMS_PRICES = {
    100: 'price_1QPR6tE4OLPaNeZAwaqvPrKz', // Ajoutez vos price_id Stripe ici
    500: 'price_1QPR9UE4OLPaNeZAPfzn3S0J',
    1000: 'price_1QPRA4E4OLPaNeZAR4LbPBso',
    5000: 'price_1QPRG1E4OLPaNeZA16wLVw5t',
}
export const StripeService = {
    createSmsCheckoutSession: withErrorHandling(async (quantity) => {
        return await apiClient.post('/society/sms-credits', {
            credits: quantity,
            priceId: SMS_PRICES[quantity],
            mode: 'payment',
        })
    }),
    redirectToCheckout: async (sessionId) => {
        const stripe = await stripePromise
        const { error } = await stripe.redirectToCheckout({
            sessionId,
        })
        if (error) {
            console.error('Error:', error)
        }
    },
}
