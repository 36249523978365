import { apiClient, withErrorHandling } from '../apiClient'
import { formatHydraData } from '../../api'

export const Society = {
    fetchParameters: async () => {
        return formatHydraData(await apiClient.get(`/fetch_parameters`))
    },
    getSociety: withErrorHandling(async (id) => {
        return formatHydraData(await apiClient.get(`/societies/${id}`))
    }),
    putParameters: withErrorHandling(async (parameters) => {
        console.log('putParameters', parameters)
        return formatHydraData(
            await apiClient.put(`/update_parameters`, parameters)
        )
    }),
}
