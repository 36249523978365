import React from 'react'
import { Xmark, CheckCircle, User } from 'iconoir-react'
import CalendarDatePicker from '../../../Commons/CalendarDatePickers/CalendarDatePicker'

const ManualNoemieDeclaration = ({
    noemie,
    rejectionReasons,
    setRejectionReasons,
    rejectedInvoices,
    setRejectedInvoices,
    selectedDate,
    setSelectedDate,
}) => {
    const [openSelectDatePicker, setOpenSelectDatePicker] =
        React.useState(false)

    const commonRejectionReasons = [
        'Absence de droit',
        'Aucun motif de rejet',
        'Individu inconnu au régime ou présence destinataire',
        'Traitement caisse',
        'Traitement en cours - Justificatif exonération erronée',
        'Prescription interdite à cette date',
        '8012 Rejet gestionnaire facture',
        'Facture TPC incomplète type contrat',
        'Traitement en cours - Facture payée manuellement',
        'Bénéficiaire inconnu en BDO',
        'L’établissement prescripteur est inconnu',
        'Autre motif',
    ]

    const handleInvoiceRejection = (invoice) => {
        setRejectedInvoices((prev) => {
            if (prev.find((i) => i.id === invoice.id)) {
                const newRejected = prev.filter((i) => i.id !== invoice.id)
                const newReasons = { ...rejectionReasons }
                delete newReasons[invoice.id]
                setRejectionReasons(newReasons)
                return newRejected
            }
            return [...prev, invoice]
        })
    }

    const getAllInvoices = () => {
        return noemie.cpamInvoiceGroups.flatMap((group) => group.cpamInvoices)
    }

    return (
        <div className="p-6">
            <div className="space-y-6">
                <div className="flex items-center gap-4">
                    <CalendarDatePicker
                        label="Date du retour"
                        customDisplay={'dddd D MMMM YYYY'}
                        date={selectedDate}
                        setDate={setSelectedDate}
                        openSelectDatePicker={openSelectDatePicker}
                        setOpenSelectDatePicker={setOpenSelectDatePicker}
                    />
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {/* Factures validées */}
                    <div className="rounded-lg border p-4">
                        <div className="mb-4">
                            <h2 className="text-lg font-semibold text-green-600">
                                Factures validées
                            </h2>
                            <p className="text-sm text-gray-500">
                                {getAllInvoices().length -
                                    rejectedInvoices.length}{' '}
                                factures
                            </p>
                        </div>
                        <div className="max-h-[600px] space-y-2 overflow-y-auto">
                            {getAllInvoices()
                                .filter(
                                    (invoice) =>
                                        !rejectedInvoices.find(
                                            (i) => i.id === invoice.id
                                        )
                                )
                                .map((invoice) => (
                                    <div
                                        key={invoice.id}
                                        onClick={() =>
                                            handleInvoiceRejection(invoice)
                                        }
                                        className="flex cursor-pointer items-center justify-between rounded-lg border p-3 hover:border-green-500 hover:bg-green-50"
                                    >
                                        <div className="flex items-center space-x-3">
                                            <CheckCircle className="h-5 w-5 text-green-500" />
                                            <div>
                                                <p className="font-medium">
                                                    {invoice.cpamInvoiceId}
                                                </p>
                                                <div className="flex items-center space-x-1 text-sm text-gray-600">
                                                    <User className="h-4 w-4" />
                                                    <span>
                                                        {
                                                            invoice.patient
                                                                .lastname
                                                        }{' '}
                                                        -{' '}
                                                        {
                                                            invoice.patient
                                                                .firstname
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="font-medium">
                                            {new Intl.NumberFormat('fr-FR', {
                                                style: 'currency',
                                                currency: 'EUR',
                                            }).format(
                                                invoice.totalInvoiceAmount
                                            )}
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>

                    {/* Factures rejetées */}
                    <div className="rounded-lg border p-4">
                        <div className="mb-4">
                            <h2 className="text-lg font-semibold text-red-600">
                                Factures rejetées
                            </h2>
                            <p className="text-sm text-gray-500">
                                {rejectedInvoices.length} factures
                            </p>
                        </div>
                        <div className="max-h-[600px] space-y-3 overflow-y-auto">
                            {rejectedInvoices.map((invoice) => (
                                <div
                                    key={invoice.id}
                                    className="rounded-lg border border-red-200 bg-red-50 p-3"
                                >
                                    <div
                                        className="mb-2 flex cursor-pointer items-center justify-between"
                                        onClick={() =>
                                            handleInvoiceRejection(invoice)
                                        }
                                    >
                                        <div className="flex items-center space-x-3">
                                            <Xmark className="h-5 w-5 text-red-500" />
                                            <div>
                                                <p className="font-medium">
                                                    {invoice.cpamInvoiceId}
                                                </p>
                                                <div className="flex items-center space-x-1 text-sm text-gray-600">
                                                    <User className="h-4 w-4" />
                                                    <span>
                                                        {
                                                            invoice.patient
                                                                .lastname
                                                        }{' '}
                                                        -{' '}
                                                        {
                                                            invoice.patient
                                                                .firstname
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="font-medium">
                                            {new Intl.NumberFormat('fr-FR', {
                                                style: 'currency',
                                                currency: 'EUR',
                                            }).format(
                                                invoice.totalInvoiceAmount
                                            )}
                                        </span>
                                    </div>
                                    <div className="pl-8">
                                        <p className="mb-1 text-sm font-medium text-gray-700">
                                            Motif du rejet
                                        </p>
                                        <select
                                            className="w-full rounded-md border border-gray-300 p-2 text-sm"
                                            value={
                                                rejectionReasons[invoice.id] ||
                                                ''
                                            }
                                            onChange={(e) => {
                                                setRejectionReasons((prev) => ({
                                                    ...prev,
                                                    [invoice.id]:
                                                        e.target.value,
                                                }))
                                            }}
                                        >
                                            <option value="">
                                                Aucun motif de rejet
                                            </option>
                                            {commonRejectionReasons.map(
                                                (reason) => (
                                                    <option
                                                        key={reason}
                                                        value={reason}
                                                    >
                                                        {reason}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        {rejectionReasons[invoice.id] ===
                                            'Autre motif' && (
                                            <textarea
                                                className="mt-2 w-full rounded-md border border-gray-300 p-2 text-sm"
                                                placeholder="Saisir le motif du rejet..."
                                                rows={2}
                                                onChange={(e) => {
                                                    setRejectionReasons(
                                                        (prev) => ({
                                                            ...prev,
                                                            [invoice.id]:
                                                                e.target.value,
                                                        })
                                                    )
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManualNoemieDeclaration
