import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Filter,
    OneFingerSelectHandGesture,
    Plus,
    Refresh,
    Search,
} from 'iconoir-react'
import SelectDateRangePicker from '../../Commons/SelectDateRangePicker'
import InputWithLoader from '../../Commons/Inputs/InputWithLoader'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import DownloadButton from '../../Commons/Buttons/DownloadButton'
import RegulationPopUpFilter from './RegulationPopUpFilter'
import { useRegulationFilter } from '../../../hooks/useRegulationFilter'
import SelectStatusModal from './SelectStatusModal'

const RegulationFilter = ({
    search,
    setSearch,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    type,
    setType,
    patient,
    setPatient,
    partner,
    setPartner,
    object,
    setObject,
    status,
    setStatus,
    user,
    setUser,
    excludeStatus,
    setExcludeStatus,
    shareFilter,
    setShareFilter,
    transferLabel,
    setTransferLabel,
    transferGroup,
    updateTransferGroup,
    setSelectAll,
    page,
    setPage,
    action,
    setAction,
    setFirstLoading,
    handleErase,
    setRefreshing,
    refreshing,
}) => {
    const navigate = useNavigate()
    const [showStatusModal, setShowStatusModal] = React.useState(false)
    const [statusForm, setStatusForm] = React.useState('todo')

    const {
        showPopUpFilter,
        setShowPopUpFilter,
        openSelectDatePicker,
        setOpenSelectDatePicker,
        loading,
        isTransferActive,
        handleTransfer,
        handleExport,
        handleCancelTransfer,
        checkActiveFilter,
        handleTransferForm,
    } = useRegulationFilter({
        search,
        dateFrom,
        dateTo,
        patient,
        partner,
        type,
        status,
        object,
        user,
        excludeStatus,
        setStatus,
        transferGroup,
        updateTransferGroup,
        setSelectAll,
        setTransferLabel,
        showStatusModal,
        setShowStatusModal,
        statusForm,
    })

    const activeFilter = checkActiveFilter()

    return (
        <div className="grid grid-cols-2 justify-between gap-4 px-2 pb-2 pt-2 lg:grid-cols-4">
            {/* Date Picker Section */}
            <div className="col-span-1 flex">
                <div className="flex items-center">
                    <SelectDateRangePicker
                        openSelectDatePicker={openSelectDatePicker}
                        setOpenSelectDatePicker={setOpenSelectDatePicker}
                        startDate={dateFrom}
                        endDate={dateTo}
                        setStartDate={setDateFrom}
                        setEndDate={setDateTo}
                        setPage={setPage}
                        page={page}
                        setAction={setAction}
                        action={action}
                    />
                </div>
            </div>

            {/* Search Section */}
            <div className="col-span-1 flex items-center justify-center space-x-2">
                <InputWithLoader
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                        setPage(1)
                    }}
                    icon={<Search />}
                    refreshing={refreshing}
                    placeholder="Rechercher"
                />
            </div>

            {/* Actions Section */}
            <div className="col-span-2 flex w-full justify-between pl-4">
                {/* Filter Button */}
                <div className="flex px-2">
                    <SecondaryButton
                        icon={<Filter />}
                        action={() => setShowPopUpFilter(true)}
                        label="Filtrer"
                        active={activeFilter}
                    />
                    {activeFilter && (
                        <button
                            className="fa fa-times cursor-pointer pl-2"
                            onClick={handleErase}
                        />
                    )}
                </div>

                {/* Transfer Actions */}
                <div className="flex">
                    {transferGroup.length > 0 && isTransferActive ? (
                        <PrimaryButton
                            icon={<Refresh className={'mr-2'} />}
                            action={handleTransfer}
                            label="Changer statut"
                            hiddenLabelOnMobile={true}
                        />
                    ) : (
                        <SecondaryButton
                            icon={
                                isTransferActive ? (
                                    <OneFingerSelectHandGesture />
                                ) : (
                                    <Refresh />
                                )
                            }
                            action={handleTransfer}
                            label={transferLabel}
                            active={isTransferActive}
                        />
                    )}
                    {isTransferActive && (
                        <button
                            onClick={handleCancelTransfer}
                            className="fa fa-times cursor-pointer pl-2"
                        />
                    )}
                </div>

                {/* Main Actions */}
                <div className="flex space-x-4">
                    <PrimaryButton
                        action={() => navigate('/transport/0')}
                        icon={<Plus className="text-xl" />}
                        label="Transport"
                        hiddenLabelOnMobile={true}
                    />
                    <DownloadButton loading={loading} action={handleExport} />
                </div>
            </div>

            {/* Filter Modal */}
            {showPopUpFilter && (
                <RegulationPopUpFilter
                    setShowPopUpFilter={setShowPopUpFilter}
                    patient={patient}
                    setPatient={setPatient}
                    partner={partner}
                    setPartner={setPartner}
                    type={type}
                    setType={setType}
                    object={object}
                    setObject={setObject}
                    status={status}
                    setStatus={setStatus}
                    user={user}
                    setUser={setUser}
                    excludeStatus={excludeStatus}
                    setExcludeStatus={setExcludeStatus}
                    shareFilter={shareFilter}
                    setShareFilter={setShareFilter}
                    setPage={setPage}
                    action={action}
                    setAction={setAction}
                    setFirstLoading={setFirstLoading}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    handleErase={handleErase}
                />
            )}

            {showStatusModal && (
                <SelectStatusModal
                    handleCloseModal={() => setShowStatusModal(false)}
                    statusForm={statusForm}
                    setStatusForm={setStatusForm}
                    handleForm={handleTransferForm}
                />
            )}
        </div>
    )
}

export default RegulationFilter
