import { formatHydraData } from '../../api'
import { apiClient, withErrorHandling } from '../apiClient'

const PATH_KEY = '/b2_files'

export const Noemie = {
    fetchAll: async (archived) => {
        let path = PATH_KEY + '?pagination=false'
        if (archived) {
            path += '&archived=true'
        } else {
            path += '&archived=false'
        }
        return formatHydraData(await apiClient.get(path))
    },
    get: async (id) => {
        return formatHydraData(await apiClient.get(PATH_KEY + '/' + id))
    },
    receivedPayment: async (data) => {
        return await apiClient.post('/noemie/received_payment', data)
    },
    resolveRejectedInvoices: async (lot) => {
        return await apiClient.put('/resolve_rejected_invoices/' + lot)
    },
    resolveManualDeclaration: async (lot) => {
        return await apiClient.post('/noemie/resolve_manual_declaration', lot)
    },
    resolveUniqueRejectedInvoices: async (invoiceId) => {
        return await apiClient.put(
            `/noemie/resolve_unique_rejected_invoice/${invoiceId}`
        )
    },
    print: withErrorHandling(async (id) => {
        return await apiClient.get('/pdf_cpam/' + id)
    }),
    close: withErrorHandling(async (id) => {
        return await apiClient.get('/noemie/close/' + id)
    }),
}
