import React, { useState } from 'react'
import ClassicInput from '../../../Commons/Inputs/ClassicInput'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import { OneFingerSelectHandGesture, Plus, SendDiagonal } from 'iconoir-react'
import { useNavigate, useLocation } from 'react-router-dom'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { toast } from 'react-toastify'
import API from '../../../../services/api'
import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'

const LotFilter = ({
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    search,
    setSearch,
    transferGroup,
    setTransferGroup,
    setFirstLoading,
    archived,
    setArchived,
    setOpenFilterModal,
    setIsReloading,
    isReloading,
}) => {
    const [transferLabel, setTransferLabel] = useState('Télétransmettre')
    let history = useNavigate()
    let location = useLocation()
    const isTransferActive = location.search.includes('?transfer=')
    const handleTransferCPAM = () => {
        setTransferLabel('Sélection')
        if (isTransferActive && transferGroup.length > 0) {
            // check if each element of transferGroup has the same payingCenterId
            const payingCenterId = transferGroup[0].payingCenter['@id']
            const isSamePayingCenter = transferGroup.every(
                (element) => element.payingCenter['@id'] === payingCenterId
            )
            const lots = transferGroup.map((element) => element['@id'])
            const data = {
                lots: lots,
                payingCenter: payingCenterId,
            }
            if (isSamePayingCenter) {
                setFirstLoading(true)
                setIsReloading(true)
                API.CpamInvoiceGroup.transfer(data)
                    .then((response) => {
                        if (response.ok) {
                            history({
                                pathname: '/facturation/lots',
                                search: null,
                            })
                            setTransferGroup([])
                            toast.success('Les lots ont été transférés')
                            setFirstLoading(false)
                            setIsReloading(false)
                        }
                    })
                    .catch((error) => {
                        error.json().then((json) => {
                            if (json) {
                                console.log(json)
                                toast.error(json.error)
                            }
                        })
                        toast.error('Une erreur est survenue lors du transfert')
                        setFirstLoading(false)
                        setIsReloading(false)
                    })
            } else {
                toast.error(
                    'Les lots sélectionnés ne sont pas du même centre payeur'
                )
            }
        } else {
            history({
                pathname: '/facturation/lots',
                search: '?transfer=true',
            })
        }
        setTransferGroup([])
    }

    return (
        <div className="flex grid grid-cols-2 items-center justify-between gap-4 px-2 pb-2 pt-2 lg:grid-cols-4 ">
            <div className="col-span-1 flex items-center justify-center">
                <i className="fa fa-search pr-2" />
                <ClassicInput
                    label="Rechercher"
                    value={search}
                    setValue={(e) => {
                        setSearch(e.target.value)
                    }}
                    id="search"
                    placeholder="Rechercher..."
                />
            </div>
            <div className="col-span-1 flex justify-center">
                <ClassicSwitch
                    label="Archives"
                    value={archived}
                    setValue={() => {
                        setArchived(!archived)
                    }}
                    info={true}
                    infoLabel={'Afficher la liste des lots archivées'}
                />
            </div>
            <div>
                {!isTransferActive ? (
                    <PrimaryButton
                        icon={<SendDiagonal />}
                        action={handleTransferCPAM}
                        customIconClass={'pr-2'}
                        disabled={isReloading}
                        label="Télétransmettre"
                        hiddenLabelOnMobile={false}
                    />
                ) : transferGroup.length === 0 ? (
                    <SecondaryButton
                        icon={<OneFingerSelectHandGesture />}
                        action={handleTransferCPAM}
                        label={transferLabel}
                        disabled={isReloading}
                        active={isTransferActive}
                        hiddenLabelOnMobile={false}
                    />
                ) : (
                    <PrimaryButton
                        icon={<SendDiagonal />}
                        customIconClass={'pr-2'}
                        action={handleTransferCPAM}
                        label="Valider et transférer"
                        disabled={isReloading}
                        hiddenLabelOnMobile={false}
                    />
                )}
            </div>
            <div className="col-span-1 col-start-2 flex justify-center lg:col-start-auto lg:justify-end ">
                <PrimaryButton
                    label="Nouveau lot"
                    title="Nouveau lot"
                    action={() => history(`/facturation/lots/0`)}
                    icon={<Plus className="text-xl" />}
                    hiddenLabelOnMobile={true}
                />
            </div>
        </div>
    )
}

export default LotFilter
