import API from './api'
import * as dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { SERIES_CPAM_INVOICE_FORM_ACTION } from '../components/App/Series/Manage/SeriesCpamInvoiceFormReducer'
import {
    getTotalsFromInvoiceFormDetails,
    seriesInitCalculatedPriceFromRunInvoiceDetails,
    getRunInvoiceFormDetailsFromRuns,
} from './InvoiceUtils'
import { formatTime } from './DateService'
import {
    formatDateFromApiToDateObject,
    formatDateToApi,
    formatTimeLocal,
    removeTimeZoneToDate,
} from './dateUtils'
import { Run } from './API/Entities/run'
import { SortRunsByGroupAndDepartureTimeForRunList } from '../tools/Sort'
import { Documents } from './Documents'

const RUN = {
    Post: async (run, historyPush, location, global, redirect = true) => {
        let data = {
            patient: run.patient['@id'],
            departureTime: formatTime(run.departureTime),
            arrivingTime: formatTime(run.arrivingTime),
            date: formatDateToApi(run.date),
            type: `/types/${run.vehicleType.label}`,
            runObject: run.runObject['@id'],
            depositLocation: run.depositLocation,
            pickUpLocation: run.pickUpLocation,
            runType: '/run_types/' + run.runType.value,
            comments: run.comments,
            margin: run.marginTime,
            isGiven: null,
            returnDepartureTime: dayjs(new Date()).format('YYYY-MM-DDTHH:mm'), // WTF ?
            amount: run.amount !== 0 ? parseFloat(run.amount) : null,
            isReturnPath: run.isReturnPath,
            masterRun: run.team ? run.team['@id'] : null,
            approximateTime: run.approximateTime,
            doNotDeleteReturnRun: run.doNotDeleteReturnRun,
            timesChanged: run.timesChanged,
            fromRunManage: true,
            userComments: run.userComments,
            note: run.note,
        }

        const effectiveDepartureTime = formatTimeLocal(
            run.effectiveDepartureTime
        )
        const effectiveArrivingTime = formatTimeLocal(run.effectiveArrivingTime)
        if (effectiveDepartureTime !== effectiveArrivingTime) {
            data = {
                ...data,
                effectiveDepartureTime: formatTime(run.effectiveDepartureTime),
                effectiveArrivingTime: formatTime(run.effectiveArrivingTime),
            }
        }

        if (global.prescriptionForm && !run.series) {
            let prescription = {
                id: global.prescriptionForm.id,
                rate: global.prescriptionForm.rate?.value,
                exoTicketModerateur:
                    global.prescriptionForm.exoTicketModerateur?.value,
                patient: run.patient['@id'],
                date: run.prsDate,
                natureAssurance: global.prescriptionForm.natureAssurance?.value,
                payingCenter: global.prescriptionForm.payingCenter
                    ? global.prescriptionForm.payingCenter['@id']
                    : null,
                practitioner: global.prescriptionForm.practitioner
                    ? global.prescriptionForm.practitioner['@id']
                    : null,
                workAccidentNumber: global.prescriptionForm.workAccidentNumber,
                workAccidentDate: global.prescriptionForm.workAccidentDate,
                dateMaternity: global.prescriptionForm.dateMaternity,
                isTPMR: global.prescriptionForm.isTPMR,
                priorApprovalDate: global.prescriptionForm.priorApprovalDate,
            }
            data = {
                ...data,
                prescription: prescription,
            }
        }
        if (!run.runObject.label.startsWith('Course payante')) {
            data = {
                ...data,
                isCpamInvoice: true,
                prsNumber: run.prsNumber,
                prsDate: run.prsDate ? run.prsDate : null,
                kilometer: run.kilometer ? parseInt(run.kilometer) : null,
            }
        }

        if (run.signature) {
            data = {
                ...data,
                signature: {
                    id: run.signatureId,
                    image: run.signature,
                    date: dayjs(run.signatureDate, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    ),
                    location: run.signatureLocation,
                },
            }
        }

        if (run.partner) {
            data = {
                ...data,
                sharingOption: run.isGiven ? run.sharingOption['@id'] : null,
                note: run.note,
                partner: run.partner['@id'],
                isGiven: run.isGiven,
                shareRoundTrip: run.shareRoundTrip,
                sharedAt: formatDateToApi(new Date()),
            }
        } else {
            data.sharingOption = null
        }

        // direct
        if (run.runType.value === 3)
            data = {
                ...data,
                waitingTime: run.waitingTime,
                isRoundTrip: true,
            }
        // indirect
        else if (run.runType.value === 4) {
            data = {
                ...data,
                waitingTime: null,
                isRoundTrip: true,
                returnDepartureTime:
                    !run.isReturnPath && run.returnTime
                        ? formatDateToApi(run.returnTime)
                        : new Date(),
            }
        } else {
            data = {
                ...data,
                isRoundTrip: false,
            }
        }

        if (!run.id || location.search.includes('?copy=')) {
            API.Runs.addRun(data)
                .then((data) => {
                    toast.success('Le transport a été ajouté.')
                    if (redirect) {
                        if (run.isSeriesTemplate) {
                            historyPush(run.id)
                        } else {
                            historyPush()
                        }
                    }
                })
                .catch((error) => {
                    if (error.json) {
                        error.json().then((data) => {
                            console.log(data)
                            toast.error(data['hydra:description'])
                        })
                    }
                })
        } else {
            try {
                await API.Runs.put(run.id, data)
                toast.success('Le transport a été modifié.')
                if (redirect) {
                    if (run.isSeriesTemplate) {
                        historyPush(run.id)
                    } else {
                        historyPush()
                    }
                }
                return true
            } catch (error) {
                if (error.json) {
                    const data = await error.json()
                    toast.error(data['hydra:description'])
                }
                return false
            }
        }
    },

    RemoveShare: async (id) => {
        const response = await Run.removeSharedRun(id)
        let result = { success: false, data: null }
        result.success = true
        result.data = response

        toast.success('Le partage a été retiré.')
        return result
    },
    GetRunsBySeriesIdAndDateBetween: async (
        seriesId,
        between,
        status,
        dispatch,
        priceBases,
        prescription
    ) => {
        API.Runs.list(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            between,
            null,
            null,
            null,
            seriesId,
            null,
            null,
            status,
            null,
            null,
            null,
            null,
            null,
            null,
            true
        ).then((data) => {
            let runs = data['hydra:member']
            // only 16 runs for 1 invoice
            if (runs.length > 16) {
                runs = runs.slice(0, 16)
            }

            const runsInvoiceDetails = getRunInvoiceFormDetailsFromRuns(runs)

            const priceInit = seriesInitCalculatedPriceFromRunInvoiceDetails(
                runsInvoiceDetails,
                priceBases,
                prescription.isTPMR,
                runs
            )

            dispatch({
                type: SERIES_CPAM_INVOICE_FORM_ACTION.SET_ALL_RUNS_DETAILS,
                payload: priceInit,
            })

            dispatch({
                type: SERIES_CPAM_INVOICE_FORM_ACTION.SET_RUNS,
                payload: runs,
            })

            const totals = getTotalsFromInvoiceFormDetails(
                priceInit,
                prescription?.rate?.value
            )

            dispatch({
                type: SERIES_CPAM_INVOICE_FORM_ACTION.INIT_PRICE_BASIS,
                payload: priceInit[0].priceBasis,
            })

            dispatch({
                type: SERIES_CPAM_INVOICE_FORM_ACTION.SET_TOTALS,
                payload: totals,
            })
        })
    },
}

export default RUN

export const runButtonTitle = (run, id) => {
    let label = ''
    if (id && id != 0) {
        label = 'Modifier'
    } else {
        label = 'Créer'
    }
    if (run.partner && !run.sharedAt) {
        if (run.id) {
            label = 'Modifier et partager'
        } else {
            label = 'Créer et partager'
        }
        if (!run.isGiven) {
            label = 'Modifier et recevoir'
        }
    }

    return label
}

const formatName = (firstname, lastname) => `${lastname} ${firstname}`.trim()

const formatAddress = (location) => ({
    full: `${location.street ? location.street + ' - ' : ''}${location.city}`,
    withZip: `${location.street ? location.street + ' - ' : ''}${
        location.zipCode ? location.zipCode : ''
    } ${location.city}`,
    cityOnly: location.city,
})

const formatTimeRange = (departureTime, arrivingTime) => {
    const startHour = formatTimeLocal(departureTime)
    const endHour = formatTimeLocal(arrivingTime)
    return {
        startHour,
        endHour,
        range: `${startHour} - ${endHour}`,
    }
}

const getUserInfo = (masterRun, currentUserId = null, assigned) => {
    if (assigned) {
        return {
            isUserRun: true,
            isNotAssignedRun: false,
            firstUserFullName: '',
            firstUserInitial: '',
            team: '',
        }
    }
    if (!masterRun) {
        return {
            isUserRun: false,
            isNotAssignedRun: true,
            firstUserFullName: '',
            firstUserInitial: '',
            team: '',
        }
    }

    const firstUser = masterRun.users[0]
    let team = ''
    if (masterRun.users?.length) {
        team = masterRun.users.map((user) => user.lastname).join(' - ')
    }

    return {
        isUserRun: currentUserId
            ? masterRun.users.some((u) => u.id === parseInt(currentUserId))
            : false,
        isNotAssignedRun: false,
        firstUserFullName: firstUser
            ? formatName(firstUser.firstname, firstUser.lastname)
            : '',
        firstUserInitial: firstUser
            ? firstUser.firstname.charAt(0) + firstUser.lastname.charAt(0)
            : '',
        team,
    }
}

export const formatDriverRuns = (runs, assigned = false) => {
    const currentUserId = localStorage.getItem('id')

    return SortRunsByGroupAndDepartureTimeForRunList(
        runs.map((run) => {
            const timeInfo = formatTimeRange(
                run.departureTime,
                run.arrivingTime
            )
            const addresses = formatAddress(run.pickUpLocation)
            const depositAddresses = formatAddress(run.depositLocation)
            const patientName = run.patient
                ? formatName(run.patient.firstname, run.patient.lastname)
                : ''

            const userInfo = getUserInfo(run.masterRun, currentUserId, assigned)

            return {
                ...run,
                fullname: patientName,
                heure: timeInfo.range,
                startHour: timeInfo.startHour,
                endHour: timeInfo.endHour,
                pickUpAddress: addresses.full,
                depositAddress: depositAddresses.full,
                ...userInfo,
            }
        })
    )
}

export const formatRegulationRuns = (runs) => {
    return SortRunsByGroupAndDepartureTimeForRunList(
        runs.map((run) => {
            const timeInfo = formatTimeRange(
                run.departureTime,
                run.arrivingTime
            )
            const addresses = formatAddress(run.pickUpLocation)
            const patientName = run.patient
                ? formatName(run.patient.firstname, run.patient.lastname)
                : ''
            const userInfo = getUserInfo(run.masterRun)

            return {
                ...run,
                fullname: patientName,
                heure: timeInfo.range,
                ride: `${run.pickUpLocation.city} - ${run.depositLocation.city}`,
                address: addresses.withZip,
                team: userInfo.team,
            }
        })
    )
}

export const processRunData = (data) => {
    let paymentMethod = {
        value: 'Course payante',
        label: 'Course payante',
        ['@id']: '/run_objects/Course payante',
    }

    if (
        data.runObject.label.startsWith('Course payante') &&
        data.runObject.label !== 'Course payante'
    ) {
        let splitedPaiementLabel = data.runObject.label.split(' ')
        let paiementLabel =
            splitedPaiementLabel[2].charAt(0).toUpperCase() +
            splitedPaiementLabel[2].slice(1)
        paiementLabel = splitedPaiementLabel[3]
            ? paiementLabel + ' ' + splitedPaiementLabel[3]
            : paiementLabel
        paymentMethod = {
            value: paiementLabel,
            label: paiementLabel,
            ['@id']: data.runObject['@id'],
        }
    }

    return {
        id: data.id,
        dateString: dayjs(data.date).format('YYYY-MM-DD'),
        patient: data.patient,
        runObject: data.runObject,
        arrivingTime: removeTimeZoneToDate(data.arrivingTime),
        departureTime: removeTimeZoneToDate(data.departureTime),
        pickUpLocation: data.pickUpLocation,
        depositLocation: data.depositLocation,
        isReturnPath: data.isReturnPath,
        isRoundTrip: data.isRoundTrip,
        loading: false,
        series: data.series,
        seriesIndex: data.seriesIndex,
        comments: data.comments,
        partner: data.partner,
        patientsAddresses: data.patient.patientsAddresses[0]
            ? data.patient.patientsAddresses.sort((a, b) => a.id - b.id)
            : null,
        patientsContacts: data.patient.patientsContacts[0]
            ? data.patient.patientsContacts.sort((a, b) => a.id - b.id)
            : null,
        showModal: false,
        showContactModal: false,
        contactAll: false,
        documents: data.documents || Documents[0],
        paymentMethod: paymentMethod,
        amount: data.amount,
        effectiveArrivingTime: removeTimeZoneToDate(
            data.effectiveArrivingTime,
            true
        ),
        effectiveDepartureTime: removeTimeZoneToDate(
            data.effectiveDepartureTime,
            true
        ),
        status: data.status,
        userComments: data.userComments,
        personToContact: [],
        isTakenInCharge: data.isTakenInCharge,
        signatureLocation:
            data.signature?.location || data.depositLocation.city,
        signatureDate: data.signature?.date
            ? dayjs(formatDateFromApiToDateObject(data.signature.date)).format(
                  'DD/MM/YYYY'
              )
            : dayjs(formatDateFromApiToDateObject(data.date)).format(
                  'DD/MM/YYYY'
              ),
        signature: data.signature?.image,
        signatureId: data.signature?.id,
    }
}
