import React, { useEffect, useReducer, useState } from 'react'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import API from '../../../../services/api'
import { formatCaisseWithCount } from '../../Regulation/Forms/PrescriptionForm'
import {
    getLotFormInitialState,
    LOT_FORM_ACTIONS,
    LotFormReducer,
} from './LotFormReducer'
import NoData from '../../NoData'
import Loader from '../../../Commons/Loaders/Loader'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import InvoiceCpamItem from '../Cpam/InvoiceCpamItem'
import DeleteButton from '../../../Commons/Buttons/DeleteButton'
import LotStats, {
    calculateTotalComplementaire,
    calculateTotalCPAM,
} from './LotStats'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { FloppyDiskArrowIn, Plus, SaveFloppyDisk, Trash } from 'iconoir-react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { findColorByStatusType, findValueByStatus } from './LotListComponent'
import { CpamInvoice } from '../../../../services/API/Entities/cpamInvoice'

const LotForm = () => {
    const history = useNavigate()
    const { id } = useParams()
    const buttonLabel = id === '0' ? 'Créer le lot' : 'Modifier le lot'
    const [loading, setLoading] = useState(true)
    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [selectedGroupedInvoices, setSelectedGroupedInvoices] = useState([])
    const [selectAllGrouped, setSelectAllGrouped] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [invoiceCountsByPayingCenter, setInvoiceCountsByPayingCenter] =
        useState({})

    const [lot, dispatch] = useReducer(
        LotFormReducer,
        undefined,
        getLotFormInitialState
    )

    const handleInvoiceSelection = (invoiceId) => {
        if (selectedInvoices.includes(invoiceId)) {
            setSelectedInvoices((prevState) =>
                prevState.filter((id) => id !== invoiceId)
            )
        } else {
            setSelectedInvoices((prevState) => [...prevState, invoiceId])
        }
    }

    const navigateToinvoice = (invoice) => {
        if (invoice.series) {
            history(`/series/${invoice.series.id}?Series_tab=Facture`)
        } else {
            history(`/transport/${invoice.runs[0].id}?tab=Facture`)
        }
    }

    const handleGroupedInvoiceSelection = (invoiceId, invoice) => {
        if (lot.status && lot.status?.label !== 'Créé') {
            navigateToinvoice(invoice)
        }

        if (selectedGroupedInvoices.includes(invoiceId)) {
            setSelectedGroupedInvoices((prevState) =>
                prevState.filter((id) => id !== invoiceId)
            )
        } else {
            setSelectedGroupedInvoices((prevState) => [...prevState, invoiceId])
        }
    }

    const addGroupedInvoices = () => {
        // Ici, je suppose que `lot.cpamInvoices` est un tableau.
        // Adaptez le code si ce n'est pas le cas.
        const groupedInvoices = lot.cpamInvoicesList.filter((invoice) =>
            selectedInvoices.includes(invoice.id)
        )
        dispatch({
            type: LOT_FORM_ACTIONS.ADD_TO_GROUPED_CPAM_INVOICES,
            payload: groupedInvoices,
        })
        // Réinitialisez les factures sélectionnées après les avoir ajoutées
        setSelectedInvoices([])
        setSelectAll(false)
    }

    const removeGroupedInvoices = () => {
        const groupedInvoices = lot.cpamInvoices.filter((invoice) =>
            selectedGroupedInvoices.includes(invoice.id)
        )
        dispatch({
            type: LOT_FORM_ACTIONS.REMOVE_FROM_GROUPED_CPAM_INVOICES,
            payload: groupedInvoices,
        })
        // Réinitialisez les factures sélectionnées après les avoir ajoutées
        setSelectedInvoices([])
        setSelectAll(false)
    }
    const toggleSelectAll = () => {
        setSelectAll((prevState) => !prevState)
        if (!selectAll) {
            setSelectedInvoices(
                lot.cpamInvoicesList
                    .filter((invoice) => invoice.status?.label !== 'Suspendue')
                    .map((invoice) => invoice.id)
            )
        } else {
            setSelectedInvoices([])
        }
    }

    const toggleSelectAllGroupedInvoices = () => {
        setSelectAllGrouped((prevState) => !prevState)
        if (!selectAllGrouped) {
            setSelectedGroupedInvoices(
                lot.cpamInvoices.map((invoice) => invoice.id)
            )
        } else {
            setSelectedGroupedInvoices([])
        }
    }

    const handleLotFormSubmit = async () => {
        if (lot.payingCenter && lot.cpamInvoices.length > 0) {
            const totalInvoiceAmountRefundedbyAMO = calculateTotalCPAM(lot)
            const totalInvoiceRefundedByComplementary =
                calculateTotalComplementaire(lot)

            let data = {
                payingCenter: lot.payingCenter['@id'],
                totalInvoiceAmountRefundedByAmo:
                    totalInvoiceAmountRefundedbyAMO,
                totalInvoiceRefundedByComplementary:
                    totalInvoiceRefundedByComplementary,
                totalInvoiceAmount:
                    totalInvoiceRefundedByComplementary +
                    totalInvoiceAmountRefundedbyAMO,
                cpamInvoices: lot.cpamInvoices.map((invoice) => invoice['@id']),
            }
            let msg = ''
            try {
                let response
                if (lot.id) {
                    response = await API.CpamInvoiceGroup.put(lot.id, data)
                    msg = 'Lot mis à jour avec succès'
                    history(-1)
                } else {
                    data = {
                        ...data,
                        status: '/cpam_invoice_group_statuses/Créé',
                    }
                    response = await API.CpamInvoiceGroup.post(data)
                    msg = 'Lot créé avec succès'
                }
                if (response.ok) {
                    toast.success(msg)
                    history('/facturation/lots')
                }
            } catch (error) {
                if (error.json) {
                    const data = await error.json()
                    toast.error(data['hydra:description'])
                } else {
                    toast.error('Erreur, veuillez contacter un administrateur')
                }
            }
        } else {
            toast.error('Veuillez sélectionner un centre payeur et une facture')
        }
    }

    const fetchPayingCenters = async (counts) => {
        console.log(counts, 'counts')
        const response = await API.payingCenter.list()
        if (response.ok) {
            const jsonData = await response.json()
            // Utilisez les counts pour inclure le nombre de factures dans chaque option
            dispatch({
                type: LOT_FORM_ACTIONS.FETCH_PAYING_CENTER_LIST,
                payload: formatCaisseWithCount(
                    jsonData['hydra:member'],
                    counts
                ),
            })
        }
    }
    const fetchCpamInvoices = async () => {
        setLoading(true)
        const response = await API.CpamInvoice.list(
            null,
            null,
            null,
            lot.payingCenter['@id'],
            false,
            'Créée'
        )
        if (response.ok) {
            const jsonData = await response.json()
            // sorte by date
            const invoices = jsonData['hydra:member'].sort((a, b) => {
                return new Date(b.date) - new Date(a.date)
            })
            setLoading(false)
            dispatch({
                type: LOT_FORM_ACTIONS.FETCH_CPAM_INVOICE_LIST,
                payload: invoices,
            })
        }
    }

    const fetchInvoicesWithCreatedStatus = async () => {
        const invoices = await CpamInvoice.fetchAll(
            null,
            'Créée',
            null,
            null,
            null,
            null,
            null,
            false
        )
        console.log(invoices, 'invoices')
        // Regroupez les factures par centre payeur
        const counts = {}
        invoices.forEach((invoice) => {
            const payingCenterId = invoice.payingCenter['@id']
            if (counts[payingCenterId]) {
                counts[payingCenterId] += 1
            } else {
                counts[payingCenterId] = 1
            }
        })
        // Vous pouvez toujours mettre à jour l'état si nécessaire
        setInvoiceCountsByPayingCenter(counts)
        return counts
    }

    const fetchLot = async () => {
        if (id !== '0') {
            setLoading(true)
            const response = await API.CpamInvoiceGroup.get(id)
            if (response.ok) {
                const jsonData = await response.json()
                dispatch({
                    type: LOT_FORM_ACTIONS.FETCH_LOT_FORM,
                    payload: jsonData,
                })
            }
        }
    }

    const fetchDatas = async () => {
        setLoading(true)
        const counts = await fetchInvoicesWithCreatedStatus()

        await Promise.all([fetchLot(), fetchPayingCenters(counts)])
        setLoading(false)
    }

    useEffect(() => {
        fetchDatas()
    }, [])
    useEffect(() => {
        if (lot.payingCenter) fetchCpamInvoices().then(() => {})
    }, [lot.payingCenter])

    return (
        <div className={'flex flex-col p-2 lg:p-5'}>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={'flex items-center justify-between py-5'}>
                        <div className={'flex flex-col pb-5'}>
                            <h1 className="text-2xl font-bold">
                                {lot.id && lot.disableActions
                                    ? 'Consultation du lot ' + lot.groupId
                                    : lot.id
                                    ? 'Modification du lot ' + lot.groupId
                                    : "Création d'un nouveau lot"}
                            </h1>
                            <p>
                                {!lot.disableActions
                                    ? 'Un lot ne contenir que des factures à destination du même centre payeur'
                                    : 'Un lot déjà transmis ne peut pas être modifié'}
                            </p>
                        </div>
                        {!lot.disableActions ? (
                            <PrimaryButton
                                action={handleLotFormSubmit}
                                label={buttonLabel}
                                icon={<FloppyDiskArrowIn />}
                                customIconClass={'pr-2'}
                            />
                        ) : (
                            <span
                                className={`flex w-32 justify-center rounded-full ${findColorByStatusType(
                                    lot.status
                                )}
                                            `}
                            >
                                {findValueByStatus(lot.status)}
                            </span>
                        )}
                    </div>
                    {lot.cpamInvoices.length > 0 ? (
                        <LotStats lotData={lot} />
                    ) : null}
                    <div className="flex w-full flex-col justify-between rounded-xl sm:flex-row sm:items-center ">
                        <div className={'w-full py-5 sm:w-1/2'}>
                            <SelectDropDownListV2
                                label={'Centre payeur'}
                                value={lot.payingCenter}
                                handleOptionChange={(e) =>
                                    dispatch({
                                        type: LOT_FORM_ACTIONS.SET_PAYING_CENTER,
                                        payload: e,
                                    })
                                }
                                isDisabled={lot.disableActions}
                                options={lot.payingCenters}
                            />
                        </div>
                        {!lot.disableActions && (
                            <div
                                className={
                                    'mt-5 flex justify-between space-x-2'
                                }
                            >
                                <PrimaryButton
                                    action={addGroupedInvoices}
                                    icon={<Plus />}
                                    title={
                                        'Ajouter au lot la facture sélectionnée'
                                    }
                                    label={'Ajouter au lot'}
                                />
                                <DeleteButton
                                    action={removeGroupedInvoices}
                                    icon={<Trash />}
                                    title={'Retirer du lot'}
                                    label={'Retirer'}
                                />
                            </div>
                        )}
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className={'flex flex-col py-2'}>
                            {lot.cpamInvoices.length > 0 ? (
                                <div
                                    className={
                                        'rounded-lg border-2 border-secondary-color p-5 pb-10'
                                    }
                                >
                                    <div
                                        className={
                                            'flex items-center justify-between'
                                        }
                                    >
                                        <h2
                                            className={
                                                'text-md font-bold sm:text-xl'
                                            }
                                        >
                                            {lot.cpamInvoices.length} factures
                                            dans le lot
                                        </h2>
                                        <div className={'flex space-x-2 pb-4'}>
                                            <SecondaryButton
                                                action={
                                                    toggleSelectAllGroupedInvoices
                                                }
                                                label={'Tout sélectionner'}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid gap-4 sm:grid-cols-2">
                                        {lot.cpamInvoices.map((invoice) => (
                                            <InvoiceCpamItem
                                                invoice={invoice}
                                                selectedInvoices={
                                                    selectedGroupedInvoices
                                                }
                                                handleInvoiceSelection={
                                                    handleGroupedInvoiceSelection
                                                }
                                                navigateToinvoice={
                                                    navigateToinvoice
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                            {!lot.disableActions && (
                                <>
                                    {lot.cpamInvoicesList.length > 0 ? (
                                        <div className={'py-5 sm:p-5'}>
                                            <div
                                                className={
                                                    'flex items-center justify-between  pb-2'
                                                }
                                            >
                                                <h2
                                                    className={
                                                        'text-xl font-bold'
                                                    }
                                                >
                                                    Non-assignée(s):{' '}
                                                    {
                                                        lot.cpamInvoicesList
                                                            .length
                                                    }
                                                </h2>
                                                <SecondaryButton
                                                    action={toggleSelectAll}
                                                    label={'Tout sélectionner'}
                                                />
                                            </div>
                                            <div className="grid gap-4 sm:grid-cols-2">
                                                {lot.cpamInvoicesList.map(
                                                    (invoice) => (
                                                        <InvoiceCpamItem
                                                            invoice={invoice}
                                                            selectedInvoices={
                                                                selectedInvoices
                                                            }
                                                            handleInvoiceSelection={
                                                                handleInvoiceSelection
                                                            }
                                                            navigateToinvoice={
                                                                navigateToinvoice
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <NoData
                                            message={
                                                'Aucune facture de disponible pour ce centre payeur'
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default LotForm
