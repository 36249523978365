import React, { useReducer, useRef, useState } from 'react'
import FreeInvoiceLineItems from './FreeInvoiceLineItems'
import { FreeInvoiceTotal } from './FreeInvoiceTotal'
import { generateFreeInvoicePDF } from './generateFreeInvoicePDF'
import BigSwitch from '../../../Commons/Switchs/BigSwitch'
import SelectDropDownListV3 from '../../../Commons/DropDownLists/SelectDropDownListV3'
import {
    Establishments,
    handleEstablishmentForm,
} from '../../../../services/API/Entities/establishments'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ModalSkeleton from '../../../Modal/ModalSkeleton'
import EstablishmentForm from '../../Establishment/EstablishmentForm'
import { FREE_INVOICE_ACTIONS } from '../../../../services/Reducers/FreeInvoiceReducer'
import { Patient } from '../../../../services/API/Entities/patient'
import { debounce } from 'lodash'
import PatientFormContent from '../../Patient/PatientFormContent'
import {
    DATE_ACTIONS,
    DateReducer,
    getDateInitialState,
} from '../../../../services/Reducers/DateReducer'
import { formatNameForSelect } from '../../../../tools/Utility'
import { ESTABLISHMENT_ACTIONS } from '../../../../services/Reducers/EstablishmentReducer'
import { PATIENT_FORM_ACTIONS } from '../../Regulation/Reducers/PatientFormReducer'
import {
    CheckCircle,
    City,
    Copy,
    Download,
    Edit,
    EyeSolid,
    User,
} from 'iconoir-react'
import StyledTextarea from '../../../Commons/StyledTextArea'
import StyledInput from '../../../Commons/StyledInput'
import CalendarDatePicker from '../../../Commons/CalendarDatePickers/CalendarDatePicker'
import { handlePatientForm } from '../../Patient/handlePatientForm'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import { useLocation, useNavigate } from 'react-router-dom'
import StatusSelector from '../../../Commons/DropDownLists/SelectStatus'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import InvoiceViewer from './FreeInvoiceConsultation'
import { FreeInvoice } from '../../../../services/API/Entities/freeInvoice'
import { toast } from 'react-toastify'
import { formatDateLocal } from '../../../../services/dateUtils'

export const statusColorConfig = {
    réglé: 'text-green-500 bg-green-100',
    réglée: 'text-green-500 bg-green-100',
    créé: 'text-gray-500 bg-gray-100',
    créée: 'text-gray-500 bg-gray-100',
    transmis: 'text-blue-500 bg-blue-100',
    relance: 'text-cyan-500 bg-cyan-100',
    transmise: 'text-blue-500 bg-blue-100',
    cloturé: 'text-purple-500 bg-purple-100',
    cloturée: 'text-purple-500 bg-purple-100',
    annulé: 'text-orange-500 bg-orange-100',
    annulée: 'text-orange-500 bg-orange-100',
    rejeté: 'text-red-500 bg-red-100',
    rejetée: 'text-red-500 bg-red-100',
}

export const statusHoverConfig = {
    réglé: 'hover:bg-green-100',
    réglée: 'hover:bg-green-100',
    créé: 'hover:bg-gray-100',
    créée: 'hover:bg-gray-100',
    transmis: 'hover:bg-blue-100',
    relance: 'hover:bg-cyan-100',
    transmise: 'hover:bg-blue-100',
    cloturé: 'hover:bg-purple-100',
    cloturée: 'hover:bg-purple-100',
    annulé: 'hover:bg-orange-100',
    annulée: 'hover:bg-orange-100',
    rejeté: 'hover:bg-red-100',
    rejetée: 'hover:bg-red-100',
}

export const statusOptions = {
    masculine: [
        { label: 'Créé', value: 'créé' },
        { label: 'Transmis', value: 'transmis' },
        { label: 'Relance', value: 'relance' },
        { label: 'Réglée', value: 'réglé' },
        { label: 'Cloturé', value: 'cloturé' },
        { label: 'Annulé', value: 'annulé' },
        { label: 'Rejeté', value: 'rejeté' },
    ],
    feminine: [
        { label: 'Créée', value: 'créée' },
        { label: 'Transmise', value: 'transmise' },
        { label: 'Relance', value: 'relance' },
        { label: 'Réglée', value: 'réglé' },
        { label: 'Cloturée', value: 'cloturée' },
        { label: 'Annulée', value: 'annulée' },
        { label: 'Rejetée', value: 'rejetée' },
    ],
}

const FreeInvoiceForm = ({
    freeInvoiceForm,
    dispatch,
    patientForm,
    patientDispatch,
    establishmentForm,
    establishmentDispatch,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const isConsult = urlParams.get('consultation')
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)
    const [patientSearch, setPatientSearch] = useState('')
    const queryClient = useQueryClient()

    const debouncedSearchRef = useRef(
        debounce((searchTerm) => {
            setPatientSearch(searchTerm)
        }, 600)
    ).current

    const [date, dateDispatch] = useReducer(
        DateReducer,
        null,
        getDateInitialState
    )

    const showEstablishmentModal = (action) => {
        dispatch({
            type: FREE_INVOICE_ACTIONS.SHOW_ESTABLISHMENT_MODAL,
            payload: action,
        })
    }

    const showPatientModal = (action) => {
        dispatch({
            type: FREE_INVOICE_ACTIONS.SHOW_PATIENT_MODAL,
            payload: action,
        })
    }
    const showModalAndFetchPatient = async (action) => {
        if (patientForm.id) {
            const patient = await Patient.fetchOne(patientForm.id)
            patientDispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: patient,
            })
            dateDispatch({
                type: DATE_ACTIONS.SET_DATE,
                payload: patient.dateOfBirth,
            })
        }
        dispatch({
            type: FREE_INVOICE_ACTIONS.SHOW_PATIENT_MODAL,
            payload: action,
        })
    }

    // fetch establishment using react query
    const { data: establishments } = useQuery({
        queryKey: ['establishments'],
        queryFn: () => Establishments.fetchAll(),
        retry: false,
    })

    const { data: patients = [], isLoading: isLoadingPatients } = useQuery({
        queryKey: ['patients', patientSearch],
        queryFn: () => Patient.fetchAllForDebounceSearch(patientSearch),
        enabled: patientSearch.trim() !== '', // Désactivez le fetch automatique
        keepPreviousData: true,
    })

    const handlePatientChange = (e) => {
        if (!e) {
            dispatch({
                type: FREE_INVOICE_ACTIONS.SET_PATIENT,
                payload: null,
            })
        } else {
            dispatch({
                type: FREE_INVOICE_ACTIONS.SET_PATIENT,
                payload: e.object,
            })
            patientDispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: e,
            })
        }
    }

    const options = [
        {
            label: 'Particulier',
            value: 'particulier',
            description: 'Client sans société',
            icon: <User />,
        },
        {
            label: 'Entreprise',
            value: 'entreprise',
            description: 'Client avec société',
            icon: <City />,
        },
    ]

    const handleEstablishmentChange = (e) => {
        if (!e) {
            dispatch({
                type: FREE_INVOICE_ACTIONS.SET_ESTABLISHMENT,
                payload: null,
            })
            establishmentDispatch({
                type: ESTABLISHMENT_ACTIONS.REMOVE_ESTABLISHMENT,
            })
        }
        dispatch({
            type: FREE_INVOICE_ACTIONS.SET_ESTABLISHMENT,
            payload: e,
        })
        establishmentDispatch({
            type: ESTABLISHMENT_ACTIONS.GET_ESTABLISHMENT,
            payload: e,
        })
    }

    const handlePatientModalForm = async () => {
        dispatch({
            type: PATIENT_FORM_ACTIONS.SET_LOADER,
            payload: true,
        })
        const formResponse = await handlePatientForm(patientForm, date)
        if (formResponse.success) {
            dispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: formResponse.data,
            })
            dispatch({
                type: FREE_INVOICE_ACTIONS.SET_PATIENT,
                payload: formResponse.data,
            })
        } else {
            console.log('error')
            dispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: patientForm,
            })
        }
    }

    const handleEstablishmentModalForm = async () => {
        dispatch({
            type: ESTABLISHMENT_ACTIONS.SET_LOADER,
            payload: true,
        })
        const formResponse = await handleEstablishmentForm(establishmentForm)
        if (formResponse.success) {
            console.log(formResponse.data)
            establishmentDispatch({
                type: ESTABLISHMENT_ACTIONS.GET_ESTABLISHMENT,
                payload: formResponse.data,
            })
            dispatch({
                type: FREE_INVOICE_ACTIONS.SET_ESTABLISHMENT,
                payload: formResponse.data,
            })
        } else {
            console.log('error')
            dispatch({
                type: ESTABLISHMENT_ACTIONS.GET_ESTABLISHMENT,
                payload: establishmentForm,
            })
        }
    }

    const { mutate: statusMutation, isPending: isPending } = useMutation({
        mutationFn: (data) => FreeInvoice.updateStatus(data),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['freeInvoice', freeInvoiceForm.id],
            })
            toast.success('Statut modifié avec succès')
        },
    })

    const handleStatusChange = (e) => {
        if (freeInvoiceForm.id) {
            statusMutation({
                id: freeInvoiceForm.id,
                status: e,
            })
        }
        if (!freeInvoiceForm.paidAt && freeInvoiceForm.isInvoice) {
            if (e === 'Réglé' || e === 'Réglée') {
                dispatch({
                    type: FREE_INVOICE_ACTIONS.SET_PAID_AT,
                    payload: formatDateLocal(new Date()),
                })
            }
        }

        dispatch({
            type: FREE_INVOICE_ACTIONS.SET_STATUS,
            payload: e,
        })
    }

    return (
        <div className="min-h-screen">
            {/* Top Bar */}
            <div className="sticky top-0 z-10 rounded-lg border-b bg-white shadow">
                <div className="mx-auto flex max-w-7xl flex-wrap items-center justify-between  space-y-4 px-4 py-4 md:space-y-0 ">
                    <h1 className="text-2xl font-semibold">
                        {freeInvoiceForm.id ? (
                            <>
                                Modifier{' '}
                                {freeInvoiceForm.isInvoice
                                    ? 'la facture'
                                    : 'le devis'}
                                <span className="text-secondary-color">
                                    {' '}
                                    n°{freeInvoiceForm.number}
                                </span>
                            </>
                        ) : (
                            `Créer ${
                                freeInvoiceForm.isInvoice
                                    ? 'une facture'
                                    : 'un devis'
                            }`
                        )}
                    </h1>
                    <div className="flex flex-wrap items-center space-x-4 space-y-4 md:space-y-0 ">
                        <StatusSelector
                            currentStatus={freeInvoiceForm.status}
                            options={
                                freeInvoiceForm.isInvoice
                                    ? statusOptions.feminine
                                    : statusOptions.masculine
                            }
                            onChange={handleStatusChange}
                            colorConfig={statusColorConfig}
                            hoverConfig={statusHoverConfig}
                            width="w-48"
                            isPending={isPending}
                            withBorder={true}
                        />
                        {freeInvoiceForm.id ? (
                            <>
                                {isConsult ? (
                                    <SecondaryButton
                                        icon={<Edit />}
                                        hiddenLabelOnMobile={true}
                                        action={() =>
                                            navigate(
                                                `/facturation/${
                                                    freeInvoiceForm.isInvoice
                                                        ? 'factures'
                                                        : 'devis'
                                                }/${freeInvoiceForm.id}`
                                            )
                                        }
                                        label={'Modifier'}
                                    />
                                ) : (
                                    <SecondaryButton
                                        icon={<EyeSolid />}
                                        hiddenLabelOnMobile={true}
                                        action={() =>
                                            navigate(
                                                `/facturation/${
                                                    freeInvoiceForm.isInvoice
                                                        ? 'factures'
                                                        : 'devis'
                                                }/${
                                                    freeInvoiceForm.id
                                                }?consultation=ok`
                                            )
                                        }
                                        label={'Consulter'}
                                    />
                                )}

                                <SecondaryButton
                                    icon={<Copy />}
                                    hiddenLabelOnMobile={true}
                                    label={'Dupliquer'}
                                    action={() =>
                                        navigate(
                                            `/facturation/${
                                                freeInvoiceForm.isInvoice
                                                    ? 'factures'
                                                    : 'devis'
                                            }/0?copy=${freeInvoiceForm.id}`
                                        )
                                    }
                                />
                                {!freeInvoiceForm.isInvoice ? (
                                    <PrimaryButton
                                        icon={
                                            <CheckCircle className={'mr-2'} />
                                        }
                                        hiddenLabelOnMobile={true}
                                        label={'Facturer le devis'}
                                        action={() =>
                                            navigate(
                                                `/facturation/factures/0?copy=${freeInvoiceForm.id}&invoice=true`
                                            )
                                        }
                                    />
                                ) : null}
                            </>
                        ) : null}
                        <PrimaryButton
                            icon={<Download />}
                            hiddenLabelOnMobile={true}
                            label={'Télécharger'}
                            action={() =>
                                generateFreeInvoicePDF(freeInvoiceForm)
                            }
                        />
                    </div>
                </div>
            </div>

            {isConsult && freeInvoiceForm.id ? (
                <InvoiceViewer
                    dispatch={dispatch}
                    freeInvoiceForm={freeInvoiceForm}
                />
            ) : (
                <div className="mx-auto max-w-7xl px-4 py-8">
                    <div className="mb-8 md:w-2/4">
                        <BigSwitch
                            options={options}
                            setSelectedOption={(e) =>
                                dispatch({
                                    type: FREE_INVOICE_ACTIONS.SET_INVOICER,
                                    payload: e,
                                })
                            }
                            selectedOption={freeInvoiceForm.invoicer}
                        />
                    </div>

                    {/* Main Form Content */}
                    <div className="space-y-6">
                        {/* Basic Information Card */}
                        <div className="rounded-lg border bg-white p-6 shadow">
                            <h2 className="border-b-4 pb-2 text-xl font-semibold">
                                Informations générales
                            </h2>
                            <div className="grid grid-cols-1 gap-6 py-4 md:grid-cols-2">
                                <div className={'w-full lg:w-1/2'}>
                                    <CalendarDatePicker
                                        openSelectDatePicker={
                                            openSelectDatePicker
                                        }
                                        setOpenSelectDatePicker={
                                            setOpenSelectDatePicker
                                        }
                                        label="Date"
                                        date={freeInvoiceForm.date}
                                        setDate={(e) =>
                                            dispatch({
                                                type: FREE_INVOICE_ACTIONS.SET_DATE,
                                                payload: e,
                                            })
                                        }
                                        customDisplay="dddd D MMM YYYY"
                                        fullWidth={false}
                                        fontSize="text-md"
                                    />
                                </div>
                                <div>
                                    {freeInvoiceForm.invoicer ===
                                    'entreprise' ? (
                                        <SelectDropDownListV3
                                            label="Entreprise"
                                            isClearable={true}
                                            isSearchable={true}
                                            addOption={() =>
                                                showEstablishmentModal(true)
                                            }
                                            handleOptionChange={
                                                handleEstablishmentChange
                                            }
                                            options={establishments}
                                            isLoading={!establishments}
                                            value={
                                                freeInvoiceForm.establishment
                                            }
                                        />
                                    ) : (
                                        <SelectDropDownListV3
                                            label="Particulier"
                                            placeholder="Rechercher un client"
                                            options={patients}
                                            isLoading={isLoadingPatients}
                                            addOption={() =>
                                                showModalAndFetchPatient(true)
                                            }
                                            isClearable={true}
                                            isSearchable={true}
                                            value={formatNameForSelect(
                                                freeInvoiceForm.patient
                                            )}
                                            handleOptionChange={
                                                handlePatientChange
                                            }
                                            handleSearchInputPatientChange={
                                                debouncedSearchRef
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Additional Information Card */}
                        <div className="rounded-lg border bg-white p-6 shadow">
                            <h2 className="border-b-4 pb-2 text-xl font-semibold">
                                Informations complémentaires
                            </h2>
                            <div className="space-y-4 py-4">
                                <StyledInput
                                    label="Titre de la facture (facultatif)"
                                    value={freeInvoiceForm.title}
                                    onChange={(e) =>
                                        dispatch({
                                            type: FREE_INVOICE_ACTIONS.SET_TITLE,
                                            payload: e.target.value,
                                        })
                                    }
                                />
                                <StyledTextarea
                                    label="Commentaires (facultatif)"
                                    value={freeInvoiceForm.comments}
                                    onChange={(e) =>
                                        dispatch({
                                            type: FREE_INVOICE_ACTIONS.SET_COMMENTS,
                                            payload: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        {/* Line Items Card */}
                        <div className="rounded-lg border bg-white p-6 shadow">
                            <h2 className="border-b-4 pb-2 text-xl font-semibold">
                                Libellés des prestations
                            </h2>
                            <FreeInvoiceLineItems
                                freeInvoiceLines={
                                    freeInvoiceForm.freeInvoiceLines
                                }
                                dispatch={dispatch}
                            />
                        </div>
                        <div className="rounded-lg border bg-white p-6 shadow">
                            <h2 className="border-b-4 pb-2 text-xl font-semibold">
                                Informations de paiement
                            </h2>
                            <FreeInvoiceTotal
                                freeInvoiceForm={freeInvoiceForm}
                                dispatch={dispatch}
                            />
                        </div>
                        {!freeInvoiceForm.isInvoice && (
                            <div className="rounded-lg border bg-white p-6 shadow">
                                <h2 className="border-b-4 pb-2 text-xl font-semibold">
                                    Signature du devis
                                </h2>
                                <div className="py-4 text-gray-600">
                                    Si ce devis vous convient, veuillez nous le
                                    retourner signé précédé de la mention :
                                    <p className="my-2 font-medium text-gray-900">
                                        « Bon pour accord et exécution de la
                                        prestation »
                                    </p>
                                    <div className="mt-4 grid grid-cols-2 gap-8">
                                        <div>
                                            <p className="font-medium text-gray-700">
                                                Date :
                                            </p>
                                            <div className="mt-2 border-b border-gray-300 py-2"></div>
                                        </div>
                                        <div>
                                            <p className="font-medium text-gray-700">
                                                Signature :
                                            </p>
                                            <div className="mt-2 border-b border-gray-300 py-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="rounded-lg border bg-white p-6 shadow">
                            <h2 className="border-b-4 pb-2 text-xl font-semibold">
                                Informations bancaires
                            </h2>
                            <div className="grid grid-cols-1 gap-4 py-4 md:grid-cols-3">
                                <div>
                                    <p className="font-medium text-gray-700">
                                        Titulaire du compte
                                    </p>
                                    <p className="text-gray-600">
                                        {
                                            freeInvoiceForm.society
                                                ?.accountHolderName
                                        }
                                    </p>
                                </div>
                                <div>
                                    <p className="font-medium text-gray-700">
                                        IBAN
                                    </p>
                                    <p className="text-gray-600">
                                        {freeInvoiceForm.society?.IBAN}
                                    </p>
                                </div>
                                <div>
                                    <p className="font-medium text-gray-700">
                                        BIC
                                    </p>
                                    <p className="text-gray-600">
                                        {freeInvoiceForm.society?.BIC}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modals */}
            {freeInvoiceForm.showEstablishmentModal && (
                <ModalSkeleton
                    Component={EstablishmentForm}
                    componentProps={{
                        establishmentForm,
                        dispatch: establishmentDispatch,
                    }}
                    label={`${
                        establishmentForm?.id ? 'Modifier' : 'Ajouter'
                    } un établissement`}
                    handleForm={handleEstablishmentModalForm}
                    handleCloseModal={() => showEstablishmentModal(false)}
                    disabledSubmit={establishmentForm.loading}
                    loader={establishmentForm.loading}
                    buttonLabel={establishmentForm?.id ? 'Modifier' : 'Ajouter'}
                />
            )}
            {freeInvoiceForm.showPatientModal && (
                <ModalSkeleton
                    label={`${patientForm.id ? 'Modifier' : 'Ajouter'} un ${
                        patientForm.isPatient ? 'patient' : 'client'
                    }`}
                    componentProps={{
                        patient: patientForm,
                        dispatch: patientDispatch,
                        date,
                        dateDispatch,
                        fromModal: true,
                    }}
                    Component={PatientFormContent}
                    handleForm={handlePatientModalForm}
                    handleCloseModal={() => showPatientModal(false)}
                    disabledSubmit={patientForm.loading}
                    loader={patientForm.loading}
                    buttonLabel={patientForm?.id ? 'Modifier' : 'Ajouter'}
                />
            )}
        </div>
    )
}

export default FreeInvoiceForm
