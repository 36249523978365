import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { StripeService } from '../services/API/stripe'

export const useSmsPurchase = () => {
    return useMutation({
        mutationFn: async ({ quantity }) => {
            const session = await StripeService.createSmsCheckoutSession(
                quantity
            )
            await StripeService.redirectToCheckout(session.id)
            return session
        },
        onError: (error) => {
            toast.error("Une erreur est survenue lors de l'achat des SMS")
            console.error('Purchase error:', error)
        },
    })
}
